import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@material-ui/core'

	const EInvoices = (props) => {
		const { t } = useTranslation()

		return (
			<>
				<TableRow>
					<TableCell variant={'head'}>{t('invoicing.operator')}</TableCell>
					<TableCell>{props.data[0].operator}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell variant={'head'}>{t('invoicing.operator_code')}</TableCell>
					<TableCell>{props.data[0].operatorCode}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell variant={'head'}>{t('invoicing.ovt')}</TableCell>
					<TableCell>{props.data[0].ovt}</TableCell>
				</TableRow>
			</>
		)
	}

	export default EInvoices
