import { useEffect, useState } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useStyles from './DecisionList.styles'
import formatDateNew from "../../../utils/formatDateNew";

const DecisionListRow = ({ result, headCells }) => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const classes = useStyles()

	const [resultEligible, setResultEligible] = useState(null)

	const successClass = classes.tableRowSuccess
	const errorClass = classes.tableRowDanger

	useEffect(() => {
		let data = result.result

		try {
			const json = typeof data === 'string' ? JSON.parse(data) : data
			data = typeof json === 'object' ? json.result : json
		} catch (e) {}

		setResultEligible(
			data === true || data === 'true'
				? true
				: data === false || data === 'false'
				? false
				: null
		)
	}, [result])

	const gotoResultDetail = (id) => {
		navigate(`/results/${id}`)
	}

	const isExcluded = (key) => {
		return headCells && !headCells.find((cell) => cell.uuid === key)
	}

	return (
		<TableRow
			className={`${classes.tableRow} ${
				resultEligible === null ? '' : resultEligible ? successClass : errorClass
			}`}
		>
			<TableCell onClick={() => gotoResultDetail(result.uuid)}>
				{t('decision.name.' + result.decision_name)}
			</TableCell>
			<TableCell onClick={() => gotoResultDetail(result.uuid)}>{result.company_name}</TableCell>
			<TableCell onClick={() => gotoResultDetail(result.uuid)}>
				{result.first_name} {result.last_name}
			</TableCell>
			<TableCell onClick={() => gotoResultDetail(result.uuid)}>
				{resultEligible !== null &&
					t(`decision.${resultEligible === true ? 'eligible' : 'not_eligible'}`)}
			</TableCell>
			<TableCell>
				<Link to={`/organisations/${result.organization_id}`}>{result.organization_id}</Link>
			</TableCell>
			{!isExcluded('createdBy') && (
				<TableCell onClick={() => gotoResultDetail(result.uuid)}>
					{result.created_by}
				</TableCell>
			)}
			<TableCell onClick={() => gotoResultDetail(result.uuid)}>{formatDateNew(result.created_at)}</TableCell>
		</TableRow>
	)
}

export default DecisionListRow
