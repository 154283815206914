import { createTheme, colors } from '@material-ui/core'
import shadows from './shadows'
import typography from './typography'

const defaultTheme = createTheme()

const theme = createTheme({
	palette: {
		background: {
			default: '#F4F6F8',
			paper: colors.common.white,
			neutral: '#f4f5f7'
		},
		primary: {
			contrastText: '#ffffff',
			main: '#5664d2'
		},
		text: {
			primary: '#172b4d',
			secondary: '#6b778c'
		},
		successBg: {
			main: 'rgba(76, 175, 80, 0.1)'
		},
		errorBg: {
			main: 'rgba(244, 67, 54, 0.1)'
		}
	},
	shape: {
		borderRadius: '4px'
	},
	shadows,
	typography,
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'*': {
					boxSizing: 'border-box',
					margin: 0,
					padding: 0
				},
				html: {
					'-webkit-font-smoothing': 'antialiased',
					'-moz-osx-font-smoothing': 'grayscale',
					height: '100%',
					width: '100%'
				},
				body: {
					backgroundColor: '#F4F6F8',
					height: '100%',
					width: '100%'
				},
				a: {
					textDecoration: 'none'
				},
				'#root': {
					height: '100%',
					width: '100%'
				}
			}
		},
		MuiSelect: {
			select: {
				'&:focus': {
					borderRadius: 'inherit'
				}
			}
		},
		MuiTableRow: {
			root: {
				'&:nth-child(even)': {
					backgroundColor: '#f9fafc'
				},
				'&:nth-child(odd)': {
					backgroundColor: '#fff'
				}
			},
			head: {
				backgroundColor: '#f9fafc !important'
			}
		},
		MuiTableCell: {
			root: {
				borderBottom: 0,
				[defaultTheme.breakpoints.down('sm')]: {
					paddingLeft: defaultTheme.spacing(1),
					paddingRight: defaultTheme.spacing(1)
				}
			}
		}
	}
})

export default theme
