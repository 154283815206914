import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	Paper,
	Table,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'

const CompanyDocuments = ({ businessId }) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)

	const getDocuments = async () => {
		setLoading(true)

		let res = await axios.get(`${API_URL}/company/trade-registry-info/${businessId}`, {
			responseType: 'blob'
		})

		try {
			const data = JSON.parse(await res.data.text())
			if (data.Error) {
				showMessage(t('company.documentDownload.error'), 'error')
			}
		} catch (e) {
			window.open(URL.createObjectURL(res.data))
		}

		setLoading(false)
	}

	return (
		<Card component={Paper}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>
				<CardHeader title={t('trade_register_extract')} />

				<Button
					onClick={getDocuments}
					disabled={loading}
					color={'primary'}
					variant={'contained'}
				>
					{t('get')}
				</Button>
			</Box>

			<Divider />

			<Table>
				<TableHead>
					<TableRow>
						<TableCell variant={'head'} width="25%">
							{t('download_pdf')}
						</TableCell>
					</TableRow>
				</TableHead>
			</Table>
		</Card>
	)
}

export default CompanyDocuments
