import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import {getAddressString, getCountry} from '../../../../utils/AddressHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { COMPANY_ADDRESS_TYPES } from '../../../../config/constants'
import formatDateNew from "../../../../utils/formatDateNew";

const CompanyGeneral = ({ result, handleRefresh, refreshing }) => {
	const { t } = useTranslation()

	return (
		<Card component={Paper}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>
				<CardHeader title={t('general_info')} />

				<Button
					color={'primary'}
					variant={'contained'}
					onClick={handleRefresh}
					disabled={refreshing}
				>
					{t('refresh')}
				</Button>
			</Box>

			<Divider />

			<Table>
				<TableBody>
					<TableRow>
						<TableCell variant={'head'} width="25%">
							{t('name')}
						</TableCell>
						<TableCell>{result.name}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'head'}>{t('business_id')}</TableCell>
						<TableCell>{result.primary_business_id}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('organizationId')}</TableCell>
						<TableCell>
							<Link to={`/organisations/${result.organization_id}`}>
								{result.organization_id}
							</Link>
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('street_address')}</TableCell>
						<TableCell>
							{getAddressString(COMPANY_ADDRESS_TYPES.visit, result.company_address) || t('company.country.empty')}
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('country')}</TableCell>
						<TableCell>
							{getCountry(COMPANY_ADDRESS_TYPES.visit, result.company_address) ||
								t('company.country.empty')}
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('legal_form')}</TableCell>
						<TableCell>{result.form_value}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('industry')}</TableCell>
						<TableCell>{result.company_business_line_value}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('sector')}</TableCell>
						<TableCell>{t('sector.' + result.company_sector)}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('created')}</TableCell>
						<TableCell>{formatDateNew(result.created_at)}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'head'}>{t('modified')}</TableCell>
						<TableCell>
							{result.modified_at ? formatDateNew(result.modified_at) : ''}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Card>
	)
}

export default CompanyGeneral
