import React from 'react'
import { useTranslation } from 'react-i18next'
import { Auth, Hub } from 'aws-amplify'
import { useNavigate, useLocation } from 'react-router'

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {
	PieChart as DashboardIcon,
	ViewModule as SmartFormIcon,
	ExitToApp as SignOutIcon,
	Business as BusinessIcon,
	AccountTree as DecisionIcon,
	List as OrganisationIcon,
	AccountCircle as AccountIcon,
	Build as AdminIcon,
	LibraryBooks as OrganisationsIcon,
	ExpandLess,
	ExpandMore,
	Settings,
	Code,
	Public as DomainIcon,
	AddToPhotos as OrganisationAddIcon,
	DoneAll as ApprovalsIcon,
	Description as ApprovalTemplateIcon
} from '@material-ui/icons'

import { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../../../context/UserContext'

import useStyles from './NavContent.styles'

const NavContent = () => {
	const { user, isInternal, isSupport } = useContext(UserContext)

	const { t } = useTranslation()

	const [navItems, setNavItems] = useState([])

	const [settingsOpen, setSettingsOpen] = useState(false)
	const [organisationsOpen, setOrganisationsOpen] = useState(false)
	const [approvalsOpen, setApprovalsOpen] = useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const items = []

		const settings = [
			{
				title: t('profile'),
				icon: <AccountIcon fontSize="small" color={'inherit'} />,
				path: '/profile'
			},
			{
				title: t('my_organisation'),
				icon: <OrganisationIcon fontSize="small" color={'inherit'} />,
				path: '/organisation',
				exact: true
			},
			{
				title: t('admin'),
				icon: <AdminIcon fontSize="small" color={'inherit'} />,
				path: '/admin'
			}
		]

		items.push(
			{
				title: t('companies'),
				icon: <BusinessIcon fontSize="small" color={'inherit'} />,
				path: '/',
				exact: true
			},
			{
				title: t('results'),
				icon: <DecisionIcon fontSize="small" color={'inherit'} />,
				path: '/results'
			},
			{
				title: t('dashboard'),
				icon: <DashboardIcon fontSize="small" color={'inherit'} />,
				path: '/dashboard',
				exact: true
			},
			{
				title: t('Domains'),
				icon: <DomainIcon fontSize="small" color={'inherit'} />,
				path: '/domains',
				exact: true
			},
			{
				title: t('nav.approvals'),
				icon: <ApprovalsIcon fontSize="small" color={'inherit'} />,
				isNestedOpen: approvalsOpen,
				onClick: (e) => {
					e.preventDefault()
					setApprovalsOpen(!approvalsOpen)
					return false
				},
				nested: [
					{
						title: t('nav.approvals.records'),
						icon: <OrganisationIcon fontSize="small" color={'inherit'} />,
						path: '/approvals'
					},
					{
						title: t('nav.approvals.templates'),
						icon: <ApprovalTemplateIcon fontSize="small" color={'inherit'} />,
						path: '/approval-templates'
					}
				]
			},
			{
				title: t('organisations'),
				icon: <OrganisationsIcon fontSize="small" color={'inherit'} />,
				isNestedOpen: organisationsOpen,
				onClick: (e) => {
					e.preventDefault()
					console.log('organisationsOpen', organisationsOpen)

					setOrganisationsOpen(!organisationsOpen)
					return false
				},
				nested: [
					{
						title: t('organisations'),
						icon: <OrganisationIcon fontSize="small" color={'inherit'} />,
						path: '/organisations'
					},
					{
						title: t('nav.createOrganisation'),
						icon: <OrganisationAddIcon fontSize="small" color={'inherit'} />,
						path: '/create-organisation'
					}
				]
			},
			{
				title: t('settings'),
				icon: <Settings fontSize="small" color={'inherit'} />,
				nested: settings,
				isNestedOpen: settingsOpen,
				onClick: (e) => {
					e.preventDefault()

					console.log('settingsOpen', settingsOpen)
					setSettingsOpen(!settingsOpen)
					return false
				}
			},

			{
				title: t('sign_out'),
				icon: <SignOutIcon fontSize="small" color={'inherit'} />,
				onClick: () => {
					Auth.signOut()
					return false
				}
			}
		)
		setNavItems(items)
	}, [user, isInternal, isSupport, t, settingsOpen, organisationsOpen, approvalsOpen])

	Hub.listen('auth', (data) => {
		switch (data.payload.event) {
			case 'signOut':
				navigate('/login')
				break
			default:
				break
		}
	})

	const classes = useStyles()

	const active = (exact, path) => {
		return (
			(exact && location.pathname === path) || (!exact && location.pathname.startsWith(path))
		)
	}

	const Menu = ({ navItems }) => {
		return (
			<List classes={{ root: classes.list }}>
				{navItems.map(({ title, icon, path, exact, onClick, nested, isNestedOpen }, i) => {
					return (
						<React.Fragment key={`nav-item-${i}`}>
							<>
								<ListItem
									button
									onClick={onClick}
									selected={active(exact, path)}
									classes={{
										root: classes.root,
										selected: classes.selected
									}}
									component={path ? Link : undefined}
									to={path}
								>
									<ListItemIcon classes={{ root: classes.icon }}>
										{icon}
									</ListItemIcon>
									<ListItemText
										classes={{ primary: classes.listItemText }}
										primary={title}
									/>

									{nested && (isNestedOpen ? <ExpandLess /> : <ExpandMore />)}
								</ListItem>

								{nested && (
									<Collapse in={isNestedOpen} timeout="auto" unmountOnExit>
										<Menu navItems={nested} />
									</Collapse>
								)}
							</>
						</React.Fragment>
					)
				})}
			</List>
		)
	}

	return <Menu navItems={navItems} />
}

export default NavContent
