import React, { useContext, useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { BASE_API_URL } from '../../../config/constants'
import { Link, useParams } from 'react-router-dom'
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AutocompleteWithSearch from '../../../components/AutocompleteWithSearch'
import { SnackbarContext } from '../../../context'
import Error from '../../../layouts/Main/components/Error'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const ApprovalTemplateDetail = () => {
	const { t } = useTranslation()

	const params = useParams()

	const { showMessage } = useContext(SnackbarContext)

	const [isEditing, setIsEditing] = useState(false)

	const [template, setTemplate] = useState({
		organization_id: '',
		country: '',
		approval_theme: '',
		from_name: '',
		from_address: '',
		identification_template: '',
		sms_message: '',
		returning_customer_template: '',
		confirmation_method: '',
		organization: null
	})
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		setIsEditing(!!params.id)
		if (params.id) {
			;(async () => {
				setLoading(true)

				await loadTemplate()

				setLoading(false)
			})()
		}
	}, [params.id])

	const loadTemplate = async () => {
		try {
			const { data } = await axios.get(`${BASE_API_URL}/v3/approval_template_configuration`, {
				params: {
					id: "eq." + params.id
				}
			});
			setTemplate(data?.[0]);
		} catch (e) {
			setError(true);
		}
	};

	const handleOrganisationSelect = (organisation) => {
		setTemplate({
			...template,
			organizationId: organisation.id
		})
	}

	const handleInputChange = (prop) => (e) => {
		setTemplate({
			...template,
			[prop]: e.target.value
		})
	}

	const createTemplate = async () => {
		return await axios.post(`${BASE_API_URL}/v3/approval_template_configuration`, {
			...template
		})
	}

	const updateTemplate = async () => {
		try {
			return await axios.patch(`${BASE_API_URL}/v3/approval_template_configuration`, {
				...template
			}, {
				params: {
					id: "eq." + params.id
				}
			});
		} catch (e) {
			// Handle any errors
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault()

		setSaving(true)

		try {
			if (isEditing) {
				await updateTemplate()
			} else {
				await createTemplate()
			}

			showMessage(t('approval.template.save.success'), 'success')
		} catch (e) {
			showMessage(t('approval.template.save.error'), 'error')
		}

		setSaving(false)
	}

	const handleSearchOrganisations = async (name, cancelToken) => {
		return await axios.get(`${BASE_API_URL}/organizations`, {
			params: {
				q: name,
				limit: 30
			},
			cancelToken: cancelToken
		})
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('approval.template')}
				</Typography>
			</Box>

			<Box display={'flex'} justifyContent={'space-between'}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Link color="inherit" variant={'subtitle2'} to="/companies">
						{t('Companies')}
					</Link>
					<Link color="inherit" variant={'subtitle2'} to="/approval-templates">
						{t('approvals')}
					</Link>
					<Typography variant={'subtitle2'} color="textPrimary">
						{t('approval.template')}
					</Typography>
				</Breadcrumbs>

				<Link to={'/approval-templates/create'}>{t('approval.templates.create')}</Link>
			</Box>

			<Box mt={4}>
				{error ? (
					<Error />
				) : loading ? (
					<LinearProgress />
				) : (
					<>
						<Card component={Paper}>
							<form noValidate autoComplete="off" onSubmit={handleFormSubmit}>
								<CardContent>
									<Grid container spacing={2} alignItems="center">
										<Grid item xs={12}>
											{isEditing ? (
												<Box my={2}>
													<Link
														to={`/organisation/${template.organization?.id}`}
													>
														Organisation: {template.organization?.name}
													</Link>
												</Box>
											) : (
												<AutocompleteWithSearch
													onSearchItems={handleSearchOrganisations}
													onItemSelect={handleOrganisationSelect}
													itemLabel={'organizationName'}
													itemValue={'id'}
													notFoundLabel={'search.no_organisations_found'}
													textFieldPlaeholder={
														'search.organisation.placeholder'
													}
												/>
											)}
										</Grid>

										<Grid item xs={12} sm={6}>
											<FormControl variant="outlined" fullWidth>
												<InputLabel id="role-select-label">
													{t('country')}
												</InputLabel>
												<Select
													required
													labelId="country-select-label"
													id="country-select"
													onChange={handleInputChange('country')}
													label={t('country')}
													value={template.country}
													disabled={isEditing}
												>
													<MenuItem value={'fi'}>
														{t('country.code.fi')}
													</MenuItem>
													<MenuItem value={'no'}>
														{t('country.code.no')}
													</MenuItem>
												</Select>
											</FormControl>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.approvalTheme')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('approvalTheme')}
												value={template.approval_theme}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.fromName')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('fromName')}
												value={template.from_name}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'email'}
												label={t('approval.fromAddress')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('fromAddress')}
												value={template.from_address}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.identificationTemplate')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange(
													'identificationTemplate'
												)}
												value={template.identification_template}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.smsMessage')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('smsMessage')}
												value={template.sms_message}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.returningCustomerTemplate')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange(
													'returningCustomerTemplate'
												)}
												value={template.returning_customer_template}
											/>
										</Grid>

										<Grid item xs={12} sm={6}>
											<TextField
												type={'text'}
												label={t('approval.confirmationMethod')}
												variant="outlined"
												required
												fullWidth={true}
												onChange={handleInputChange('confirmationMethod')}
												value={template.confirmation_method}
											/>
										</Grid>
									</Grid>
								</CardContent>

								<Divider />

								<CardContent>
									<Button
										type={'submit'}
										variant="contained"
										color="primary"
										disabled={saving}
									>
										{t('approval.template.save')}
									</Button>
								</CardContent>
							</form>
						</Card>
					</>
				)}
			</Box>
		</>
	)
}

export default ApprovalTemplateDetail
