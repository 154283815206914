import { useTranslation } from 'react-i18next'
import { useEffect, useState, useContext } from 'react'

import { Box, Button, LinearProgress, Typography, Breadcrumbs } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link } from 'react-router-dom'

import axios from '../../../utils/axios'
import { AUTH_API_URL } from '../../../config/constants'

import { OrganisationTable, UserForm } from '../../../components/organisation'
import Error from '../../../layouts/Main/components/Error'
import { UserContext } from '../../../context/UserContext'

const UserOrganisation = () => {
	const { isAdmin } = useContext(UserContext)

	const { t } = useTranslation()

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(false)
	const [loaded, setLoaded] = useState(false)

	const [userModalOpen, setUserModalOpen] = useState(false)
	const [userEdit, setUserEdit] = useState(null)

	const [organisation, setOrganisation] = useState({})

	useEffect(() => {
		if (!isAdmin || loaded) return

		const loadUsers = () => {
			setLoading(true)
			axios
				.get(`${AUTH_API_URL}/organization/users`)
				.then(({ data }) => {
					setResults(
						data.map((user) => {
							if (typeof user.data === 'string') {
								try {
									user = { ...user, ...JSON.parse(user.data) }
									console.log('user', user)
								} catch (e) {
									console.log('e', e)
								}
							}

							return user
						})
					)
					setLoading(false)
					setLoaded(true)
				})
				.catch((e) => {
					setError(true)
				})
		}

		loadUsers()
	}, [loaded])

	useEffect(() => {
		axios.get(`${AUTH_API_URL}/organization`).then(({ data }) => {
			setOrganisation(data)
		})
	}, [])

	const handleUserFormModal = (user = null) => {
		setUserEdit(user)
		setUserModalOpen(true)
	}

	const loadUsers = () => {
		setLoaded(false)
	}

	const handleClose = (e, reloadUsers = false) => {
		setUserEdit(null)
		setUserModalOpen(false)

		reloadUsers && loadUsers()
	}

	return (
		<>
			<Box mt={2}>
				<Typography variant="h4" component="h2" gutterBottom>
					{t('my_organisation')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('companies')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('my_organisation')}
				</Typography>
				{organisation && (
					<Typography variant={'subtitle2'} color="textPrimary">
						{organisation.name}
					</Typography>
				)}
			</Breadcrumbs>

			{isAdmin && (
				<>
					<Box mt={4} display="flex" justifyContent="space-between">
						<Typography variant="h3" component="h3" gutterBottom={true}>
							{t('user.list')}
						</Typography>

						<Box my="auto">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleUserFormModal(null)
								}}
							>
								{t('user.add')}
							</Button>
						</Box>
					</Box>

					{userModalOpen ? (
						<UserForm user={userEdit} onModalClose={handleClose} />
					) : (
						<></>
					)}

					<Box mt={4}>
						{error ? (
							<Error />
						) : loading ? (
							<LinearProgress />
						) : (
							<OrganisationTable results={results} />
						)}
					</Box>
				</>
			)}
		</>
	)
}

export default UserOrganisation
