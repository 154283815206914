import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import axios from '../../utils/axios'
import useStyles from './AutocompleteWithSearch.styles'
import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const AutocompleteWithSearch = ({
	onSearchItems,
	onItemSelect,
	itemLabel,
	itemValue,
	notFoundLabel,
	textFieldPlaeholder
}) => {
	const { t } = useTranslation()

	const [searching, setSearching] = useState(false)
	const [searchStartingReady, setSearchStartingReady] = useState(false)
	const [items, setItems] = useState([])
	const [selectedItem, setSelectedItem] = useState(null)

	const SEARCH_DEBOUNCE_TIMEOUT = 200
	const [searchDebounceHandler, setSearchDebounceHandler] = useState(null)
	const [searchRequestSource, setSearchRequestSource] = useState(null)

	const searchItems = async (name) => {
		setSearching(true)

		const source = axios.CancelToken.source()
		setSearchRequestSource(source)

		try {
			const { data } = await onSearchItems(name, source.token)

			setItems(data)
		} catch (e) {
			if (!axios.isCancel(e)) {
				setItems([])
			}
		}

		finishSearching()
	}

	const cancelSearching = () => {
		if (searchRequestSource) {
			searchRequestSource.cancel('The request has been cancelled due to another new request')
		}
		if (searchDebounceHandler) {
			clearTimeout(searchDebounceHandler)
			setSearchDebounceHandler(null)
		}

		finishSearching()
	}

	const finishSearching = () => {
		setSearchRequestSource(null)
		setSearching(false)
	}

	const handleNameChange = (event, name) => {
		cancelSearching()

		if (!name || name.length < 3) {
			setSearchStartingReady(false)
			finishSearching()
			setItems([])
			return
		}

		setSearchStartingReady(true)

		setSearchDebounceHandler(
			setTimeout(() => {
				searchItems(name)
			}, SEARCH_DEBOUNCE_TIMEOUT)
		)
	}

	const classes = useStyles()

	return (
		<>
			<Autocomplete
				id="autocomplete-search"
				className={classes.searchBox}
				fullWidth
				value={selectedItem}
				options={items}
				filterOptions={(options) => options}
				getOptionLabel={(selectedItem) => selectedItem[itemLabel || 'name']}
				onChange={(event, selected) => {
					setSelectedItem(selected)
					onItemSelect(selected)
				}}
				onInputChange={handleNameChange}
				loading={searching}
				noOptionsText={
					searchStartingReady ? t(notFoundLabel) : t('company.add.search.not_ready')
				}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={t(textFieldPlaeholder)}
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{searching ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							)
						}}
					/>
				)}
			/>
		</>
	)
}

export default AutocompleteWithSearch
