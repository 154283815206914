import { useTranslation } from 'react-i18next'
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'

const ApprovalsTableDetailedTable = ({ data }) => {
	const { t } = useTranslation()

	return (
		<>
			<Box>
				<Typography variant="h6" gutterBottom component="div">
					{t('approvals')}
				</Typography>
				<Table size="small">
					<TableBody>
						<TableRow>
							<TableCell width={'30%'} variant={'th'}>
								{t('approvals.merchant')}
							</TableCell>
							<TableCell>{data.merchant}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'th'}>{t('phone_number')}</TableCell>
							<TableCell>{data.phone_number}</TableCell>
						</TableRow>

						<TableRow>
							<TableCell variant={'th'}>{t('approvals.redirectUrl')}</TableCell>
							<TableCell>{data.redirect_url}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'th'}>{t('approvals.primaryWebhookUrl')}</TableCell>
							<TableCell>{data.primary_webhook_url}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'th'}>
								{t('approvals.secondaryWebhookUrl')}
							</TableCell>
							<TableCell>{data.secondary_webhook_url}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'th'}>
								{t('approvals.verificationMethod')}
							</TableCell>
							<TableCell>{data.verification_method}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell variant={'th'}>
								{t('approvals.identificationMethod')}
							</TableCell>
							<TableCell>{data.identification_method}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>
		</>
	)
}

export default ApprovalsTableDetailedTable
