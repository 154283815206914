import React from 'react'

import { AppBar } from '@material-ui/core'

import useStyles from './AppHeader.styles'

const AppHeader = (props) => {
	const classes = useStyles()

	return (
		<AppBar
			position="fixed"
			elevation={0}
			color={'primary'}
			classes={{
				root: classes.appBar,
				colorPrimary: classes.appBar
			}}
		>
			{props.children}
		</AppBar>
	)
}

export default AppHeader
