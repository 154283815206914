import { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'

import axios from 'utils/axios'
import { AUTH_API_URL, INTERNAL_API_URL } from 'config/constants'

import DecisionChart from './DecisionChart'
import DecisionResultChart from './DecisionResultChart'
import ApprovalRequestChart from './ApprovalRequestChart'
import InformationRequestChart from './InformationRequestChart'

import useStyles from './Stats.styles'

const Stats = () => {
	const classes = useStyles()
	const [organizations, setOrganizations] = useState(null)
	const [decisionNames, setDecisionNames] = useState([])

	useEffect(() => {
		axios
			.get(`/organization?limit=50`)
			.then(({ data }) => {
				setOrganizations(data)
			})
			.catch(() => {})
	}, [])

	return (
		<>
			<Paper className={classes.paper}>
				<DecisionChart
					organizations={organizations}
					decisionNames={decisionNames}
					setDecisionNames={setDecisionNames}
				/>
			</Paper>
			<Paper className={classes.paper}>
				<DecisionResultChart organizations={organizations} decisionNames={decisionNames} />
			</Paper>
			<Paper className={classes.paper}>
				<ApprovalRequestChart organizations={organizations} />
			</Paper>
			<Paper className={classes.paper}>
				<InformationRequestChart organizations={organizations} />
			</Paper>
		</>
	)
}

export default Stats
