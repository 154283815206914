import {
	Box,
	Button,
	Card,
	CardHeader,
	Chip,
	Divider,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from '../../../../utils/axios'
import { COMPANY_API_URL } from '../../../../config/constants'
import formatDate from '../../../../utils/formatDate'
import { SnackbarContext } from '../../../../context'

const CompanyManagement = ({ data, setData, country, businessId, refreshing, setRefreshing }) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)

	const [persons, setPersons] = useState(null)

	useEffect(() => {
		setPersons(
			data
				? data.map((item) => {
						item.isNew = isNew(item.roleStartDate)
						return item
				  })
				: null
		)
	}, [data])

	const handleRefresh = async () => {
		setLoading(true)
		setRefreshing(true)

		try {
			let res = await axios.get(`${COMPANY_API_URL}/representatives`, {
				params: {
					businessId,
					country: country,
					refresh: true
				}
			})
			setData(res.data)
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
		setRefreshing(false)
	}

	const isNew = (startDate) => {
		if (!startDate) return null

		startDate = new Date(startDate)
		const now = new Date()

		if (
			now.getMonth() -
				startDate.getMonth() +
				12 * (now.getFullYear() - startDate.getFullYear()) >
			1
		) {
			return false
		} else {
			return true
		}
	}

	return (
		<Card component={Paper}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>
				<CardHeader title={t('management')} />

				<Button
					color={'primary'}
					variant={'contained'}
					onClick={handleRefresh}
					disabled={loading || refreshing}
				>
					{t('get')}
				</Button>
			</Box>

			{loading ? (
				<LinearProgress />
			) : (
				<>
					{persons && (
						<>
							<Divider />

							<Table>
								<TableHead>
									<TableRow>
										<TableCell>{t('name')}</TableCell>
										<TableCell>{t('position')}</TableCell>
										<TableCell>{t('date')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{persons.map((item, i) => (
										<TableRow key={i}>
											<TableCell>
												{item.firstName} {item.lastName}{' '}
												{item.isNew && (
													<Chip
														label={t('new')}
														variant="outlined"
														color="primary"
														size="small"
													/>
												)}
											</TableCell>
											<TableCell>{item.role}</TableCell>
											<TableCell>
												{formatDate(item.roleStartDate, false)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</>
					)}
				</>
			)}
		</Card>
	)
}

export default CompanyManagement
