import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CompanyListTable from './CompanyListTable'

import { Box, Button } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'

import Error from '../../../layouts/Main/components/Error'
import { UserContext } from '../../../context'

import useStyles from './CompanyList.styles'

const CompanyList = () => {
	const { t } = useTranslation()

	const { isSupport } = useContext(UserContext)

	const navigate = useNavigate()

	const [error, setError] = useState(false)

	const classes = useStyles()

	return (
		<>
			<div className={classes.header}>
				<Typography variant={'h3'} gutterBottom>
					{t('company_list')}
				</Typography>
			</div>

			<Box mt={4}> {error ? <Error /> : <CompanyListTable setError={setError} />}</Box>
		</>
	)
}

export default CompanyList
