import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	tableRowSuccess: {
		backgroundColor: `${theme.palette.successBg.main} !important`
	},
	tableRowDanger: {
		background: `${theme.palette.errorBg.main} !important`
	},
	tableRow: {
		cursor: 'pointer'
	},
	borderBottom: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
	},
	nestedTableCell: {
		padding: `0 0 ${theme.spacing(6)}px`,
		background: '#fff !important'
	}
}))

export default useStyles
