import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from '../../../utils/axios'
import {BASE_API_URL } from '../../../config/constants'

import {
	LinearProgress,
	Paper,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Box,
	Typography,
	Breadcrumbs,
	Button
} from '@material-ui/core'

import Error from '../../../layouts/Main/components/Error'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import formatDate from '../../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import formatCurrency from '../../../utils/formatCurrency'
import TableCellWithTooltip from '../../../components/shared/TableCellWithTooltop'
import { SnackbarContext } from '../../../context'

const DecisionDetail = () => {
	const { t, i18n } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const params = useParams()

	const [result, setResult] = useState(null)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	const [isSavingDomain, setIsSavingDomain] = useState(false)

	useEffect(() => {
		const load = async () => {
			try {
				setLoading(true)

				const { data } = await axios.get(
					`${BASE_API_URL}/v3/decision_details?uuid=eq.${params.id}`
				)
				setResult(data[0])

				setLoading(false)
			} catch (error) {
				setError(true)
			}
		}

		load()
	}, [params.id])

	const handleAddCompanyDomain = async () => {
		setIsSavingDomain(true)
		try {
			const domain = result.personEmail.split('@')[1]

			await axios.post(`${BASE_API_URL}/global_email_domains`, {
				primaryBusinessId: result.primaryBusinessId,
				name: domain,
				domain: domain
			})

			showMessage(t('domain.created'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setIsSavingDomain(false)
	}

	const parseValue = (value, key) => {
		return typeof value === 'boolean'
			? value.toString()
			: key === 'limit'
			? formatCurrency(value)
			: key === 'dataRetrievedAt'
			? formatDate(value, true)
			: value
	}

	const NestedTable = ({ data }) => {
		const { t } = useTranslation()

		return (
			<>
				{Object.keys(data).map((key) => {
					const value = parseValue(data[key], key)
					return (
						<React.Fragment key={key}>
							{data[key] && typeof data[key] === 'object' ? (
								<></>
							) : (
								<TableRow>
									<TableCell width="25%" component="th" scope="row">
										<TableCellWithTooltip
											title={`decision.result.${key}`}
											info={`decision.result.${key}.info`}
										/>
									</TableCell>

									<TableCell>
										{typeof data[key] === 'boolean'
											? t(`decision.result.${value}`)
											: value}
									</TableCell>
								</TableRow>
							)}
						</React.Fragment>
					)
				})}
			</>
		)
	}

	const Row = ({ data }) => {
		let json
		if (typeof data === 'string') {
			try {
				json = JSON.parse(data)
			} catch (error) {
				console.log(error)
			}
		} else {
			json = data
		}

		return data ? (
			<>
				{json && json !== true ? (
					<NestedTable data={json} />
				) : (
					<TableRow>
						<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
							data
						</TableCell>
					</TableRow>
				)}
			</>
		) : (
			<></>
		)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('nav.decision')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('nav.companies')}
				</Link>
				<Link color="inherit" variant={'subtitle2'} to="/results">
					{t('nav.decisions')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('nav.decision')}
				</Typography>
			</Breadcrumbs>

			<Box mt={4}>
				{error ? (
					<Error />
				) : loading ? (
					<LinearProgress />
				) : (
					<>
						{result && result.company && (
							<Box mb={6}>
								<Typography variant="subtitle1" gutterBottom={true}>
									{t('company')}
								</Typography>

								<TableContainer component={Paper}>
									<Table aria-label="Decision company Table">
										<TableHead>
											<TableRow>
												<TableCell variant={'head'} width="25%">
													{t('company_name')}
												</TableCell>
												<TableCell variant={'head'} width="25%">
													{t('company.primary_business_id')}
												</TableCell>
												<TableCell>{t('organizationId')}</TableCell>
												<TableCell variant={'head'} width="25%">
													{t('created_at')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>
													<Link to={`/companies/${result.companyId}`}>
														{result.company?.name}
													</Link>
												</TableCell>
												<TableCell>{result?.primaryBusinessId}</TableCell>
												<TableCell>
													<Link
														to={`/organisations/${result.organizationId}`}
													>
														{result.organizationId}
													</Link>
												</TableCell>
												<TableCell>
													{formatDate(result.companyCreatedAt)}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}

						<Box mb={6}>
							<Typography variant="subtitle1" gutterBottom={true}>
								{t('overview')}
							</Typography>
							<TableContainer component={Paper}>
								<Table aria-label="Decision Overview Table">
									<TableBody>
										<TableRow>
											<TableCell width="25%">
												<TableCellWithTooltip
													title={'decisions.header'}
													info={`decisions.header.${result.decision?.model}.info`}
												/>
											</TableCell>
											<TableCell>
												{i18n.exists(
													'decision.name.' + result.decision?.name
												) && t('decision.name.' + result.decision?.name)}
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell width="25%">{t('company_name')}</TableCell>
											<TableCell>{result.companyName}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell width="25%">
												{t('primaryBusinessId')}
											</TableCell>
											<TableCell>{result.primaryBusinessId}</TableCell>
										</TableRow>

										{result.decision?.model?.toLowerCase() ===
											'company-person-decision' && (
											<>
												<TableRow>
													<TableCell width="25%">
														{t('person_name')}
													</TableCell>
													<TableCell>
														{result.personFirstName}{' '}
														{result.personLastName}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell width="25%">
														{t('person.email')}
													</TableCell>
													<TableCell>
														<Box
															display={'flex'}
															gridGap={48}
															alignItems={'center'}
														>
															{result.personEmail}

															{result.personEmail && (
																<Button
																	variant="contained"
																	color={'primary'}
																	onClick={handleAddCompanyDomain}
																	disabled={isSavingDomain}
																>
																	{t('person.addDomain')}
																</Button>
															)}
														</Box>
													</TableCell>
												</TableRow>
											</>
										)}

										<TableRow>
											<TableCell>{t('result')}</TableCell>
											<TableCell>
												{i18n.exists(
													'decision.result.' +
														result.decision?.result?.result
												) &&
													t(
														'decision.result.' +
															result.decision?.result?.result
													)}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												{t('decision.result.description')}
											</TableCell>
											<TableCell>
												{result.decision?.result?.description &&
													t(
														'decision.description.' +
															result.decision?.result?.description
													)}
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>{t('created_by')}</TableCell>
											<TableCell>{result.createdBy}</TableCell>
										</TableRow>

										<TableRow>
											<TableCell>{t('created_at')}</TableCell>
											<TableCell>{formatDate(result.createdAt)}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
						{result && result.person && (
							<Box mb={6}>
								<Typography variant="subtitle1" gutterBottom={true}>
									{t('person')}
								</Typography>

								<TableContainer component={Paper}>
									<Table aria-label="Decision person Table">
										<TableHead>
											<TableRow>
												<TableCell variant={'head'} width="25%">
													{t('person')}
												</TableCell>
												<TableCell variant={'head'} width="25%">
													{t('person.email')}
												</TableCell>
												<TableCell variant={'head'} width="25%">
													{t('created_at')}
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>{result.person?.fullName}</TableCell>
												<TableCell>{result.person?.email}</TableCell>
												<TableCell>{result.personCreatedAt}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}
						{result.decision && result.decision.response && (
							<Box mb={6}>
								<Typography variant="subtitle1" gutterBottom={true}>
									{t('response')}
								</Typography>

								<TableContainer component={Paper}>
									<Table aria-label="Decision Response Table">
										<TableBody>
											<Row data={result.decision.response} />
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}
					</>
				)}
			</Box>
		</>
	)
}

export default DecisionDetail
