import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Box, Collapse, IconButton, TableCell, TableRow } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import formatDate from '../../../../../utils/formatDate'
import ApprovalsTablePromptIdentification from './ApprovalsTablePromptIdentification'
import ApprovalsTableDetailedTable from './ApprovalsTableDetailedTable'

const ApprovalsTableRow = ({ data }) => {
	const { t } = useTranslation()

	const [open, setOpen] = useState(false)

	return (
		<>
			<TableRow>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
					</IconButton>
				</TableCell>
				<TableCell>{data.status}</TableCell>
				<TableCell>{data.email}</TableCell>
				<TableCell>
					{data.country && t(`country.code.${data.country.toLowerCase()}`)}
				</TableCell>
				<TableCell>
					{data.organization_id && (
						<Link to={`/organisations/${data.organization_id}`}>
							{t('nav.to.organisation')}
						</Link>
					)}
				</TableCell>
				<TableCell>
					{/*{data.person_id && (*/}
					{/*	<Link to={`/persons/${data.person_id}`}>{t('nav.to.person')}</Link>*/}
					{/*)}*/}

					{data.person_id}
				</TableCell>
				<TableCell>{formatDate(data.createdAt)}</TableCell>
				<TableCell>{formatDate(data.finalizedAt)}</TableCell>
			</TableRow>

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box my={2}>
							{data.promptIdentification && (
								<ApprovalsTablePromptIdentification
									data={data.promptIdentification}
								/>
							)}

							<ApprovalsTableDetailedTable data={data} />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default ApprovalsTableRow
