import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import axios from '../../../../utils/axios'
import { INTERNAL_API_URL } from '../../../../config/constants'
import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import CompanyDomainForm from './CompanyDomainForm'
import formatDate from '../../../../utils/formatDate'

const CompanyDomains = ({ businessId, companyId, companyName }) => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)

	const [domains, setDomains] = useState([])

	useEffect(() => {
		loadDomains()
	}, [])

	const loadDomains = async () => {
		setLoading(true)

		const { data } = await axios.get(`/company_domain?company_id=eq.${companyId}`)

		console.log('domains', data)
		setDomains(data)

		setLoading(false)
	}

	const handleAddDomain = () => {
		setModalOpen(true)
	}

	const handleModalClose = () => {
		setModalOpen(false)
	}

	return (
		<>
			{modalOpen && (
				<CompanyDomainForm
					handleModalClose={handleModalClose}
					businessId={businessId}
					loadDomains={loadDomains}
					loading={loading}
					setLoading={setLoading}
					defaultName={companyName}
				/>
			)}

			<Card component={Paper}>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>
					<CardHeader title={t('company.emailDomains')} />

					<Button
						onClick={handleAddDomain}
						disabled={loading}
						color={'primary'}
						variant={'contained'}
					>
						{t('company.emailDomains.add')}
					</Button>
				</Box>

				<Divider />

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t('name')}</TableCell>
							<TableCell>{t('domain')}</TableCell>
							<TableCell>{t('createdAt')}</TableCell>
							<TableCell>{t('active')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{domains.map((result, index) => (
							<TableRow key={index}>
								<TableCell>{result.name}</TableCell>
								<TableCell>{result.domain}</TableCell>
								<TableCell>{formatDate(result.createdAt, false)}</TableCell>
								<TableCell>{result.active ? 'true' : 'false'}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Card>
		</>
	)
}

export default CompanyDomains
