import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	searchBox: {
		backgroundColor: theme.palette.primary.contrastText,
		borderRadius: theme.spacing(2)
	}
}))

export default useStyles
