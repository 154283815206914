// import './Dashboard.css'

import { Box, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { UserContext } from '../../context'
import Stats from '../../components/stats/Stats'

const Dashboard = () => {
	const { t } = useTranslation()

	const { user } = useContext(UserContext)

	return (
		<>
			<Box mb={4}>
				<Typography variant={'h3'} gutterBottom={true}>
					{user && user.username
						? t('welcome_username', { username: user.username })
						: t('welcome')}
				</Typography>
			</Box>

			<Stats />
		</>
	)
}

export default Dashboard
