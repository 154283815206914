import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core'

const ApprovalRequestTable = (props) => {
	if (props.tableData && props.tableData.length) {
		return (
			<TableContainer component={Paper}>
				<Table aria-label="Approval Request Table">
					<TableHead>
						<TableRow>
							<TableCell width="25%">ID</TableCell>
							<TableCell width="25%">Channel</TableCell>
							<TableCell width="25%">Request</TableCell>
							<TableCell width="25%">Response</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.tableData.map((approvalRequest, i) => (
							<TableRow key={`approval-request-table-row-${i}`}>
								<TableCell>{approvalRequest.id}</TableCell>
								<TableCell>{approvalRequest.channel}</TableCell>
								<TableCell>
									<pre>{JSON.stringify(approvalRequest.request, null, 2)}</pre>
								</TableCell>
								<TableCell>
									<pre>{JSON.stringify(approvalRequest.response, null, 2)}</pre>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	} else {
		return <div />
	}
}

export default ApprovalRequestTable
