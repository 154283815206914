import DecisionTableTableRow from './DecisionApiTableRow'
import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import getSortedDecisionData from '../../../utils/getSortedDecisionData'

const DecisionApiTable = ({ tableData }) => {
	const [sortedData, setSortedData] = useState([])

	useEffect(() => {
		if (tableData && tableData.length) {
			setSortedData(getSortedDecisionData(tableData))
		}
	}, [tableData])

	return (
		<>
			{sortedData &&
				sortedData.map((decisionApi, i) => (
					<Box key={i} mt={i === 0 ? 0 : 2}>
						<DecisionTableTableRow
							key={`decision-api-table-row-${i}`}
							decisionApi={decisionApi}
						/>
					</Box>
				))}
		</>
	)
}

export default DecisionApiTable
