import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import {
	Box,
	Button,
	Card,
	CardHeader,
	Divider,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import CompanyAmlPerson from './CompanyAmlPerson'

const CompanyAmlPersons = ({ data, loadAmlData, loading }) => {
	const { t } = useTranslation()

	const [amlData, setAmlData] = useState(null)

	useEffect(() => {
		setAmlData(data.filter((item) => item.data && item.data.amlStatus))
	}, [data])

	return (
		<>
			<Card component={Paper}>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pr={2}>
					<CardHeader title={t('company.amlStatus')} />

					<Button
						color={'primary'}
						variant={'contained'}
						onClick={loadAmlData}
						disabled={loading}
					>
						{t('company.retrieveAmlData')}
					</Button>
				</Box>
				{loading ? (
					<LinearProgress />
				) : (
					<>
						{amlData && amlData.length > 0 && (
							<>
								<Divider />

								<Table>
									<TableHead>
										<TableRow>
											<TableCell>{t('name')}</TableCell>
											<TableCell>{t('email')}</TableCell>
											<TableCell>{t('amlStatus.amlMatch')}</TableCell>
											<TableCell>
												{t('amlStatus.businessProhibition')}
											</TableCell>
											<TableCell>{t('amlStatus.remarks')}</TableCell>
											<TableCell>{t('amlStatus.pep')}</TableCell>
											<TableCell>{t('amlStatus.rca')}</TableCell>
											<TableCell>{t('amlStatus.sanction')}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{amlData.map((person, i) => {
											return (
												<CompanyAmlPerson
													person={person}
													key={'person-' + i}
												/>
											)
										})}
									</TableBody>
								</Table>
							</>
						)}
					</>
				)}
			</Card>
		</>
	)
}

export default CompanyAmlPersons
