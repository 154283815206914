import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useState } from 'react'
import formatDate from '../../../utils/formatDate'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const HistoryTable = ({ histories }) => {
	const { t } = useTranslation()

	const HistoryTableRow = ({ history }) => {
		const { t } = useTranslation()
		const [open, setOpen] = useState(false)

		const Row = ({ data, oldData, dataKey, t }) => {
			return (
				<>
					{data && (
						<>
							{!dataKey || (data[dataKey] && typeof data[dataKey] === 'object') ? (
								<>
									{/*<TableRow>*/}
									{/*	<TableCell*/}
									{/*		variant={'head'}*/}
									{/*		width={10}*/}
									{/*		align={'top'}*/}
									{/*		valign={'top'}*/}
									{/*	>*/}
									{/*		{dataKey}*/}
									{/*	</TableCell>*/}
									{/*</TableRow>*/}

									{Object.keys(dataKey ? data[dataKey] : data).map((k, i) => (
										<Row
											key={i}
											data={dataKey ? data[dataKey] : data}
											oldData={
												dataKey && oldData ? oldData[dataKey] : oldData
											}
											dataKey={k}
											t={t}
										/>
									))}
								</>
							) : (
								<TableRow
									selected={
										oldData &&
										data[dataKey] &&
										oldData[dataKey] &&
										data[dataKey] !== oldData[dataKey]
									}
								>
									<TableCell width={20} component="th" scope="row">
										{t(dataKey)}
									</TableCell>
									<TableCell width={40}>
										{data[dataKey] && data[dataKey]}
									</TableCell>
									<TableCell width={40}>
										{oldData && oldData[dataKey] && oldData[dataKey]}
									</TableCell>
								</TableRow>
							)}
						</>
					)}
				</>
			)
		}

		return (
			<>
				<TableRow>
					<TableCell>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => setOpen(!open)}
						>
							{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						</IconButton>
					</TableCell>
					<TableCell>{history.action}</TableCell>
					<TableCell>{history.created_by_name}</TableCell>
					<TableCell>{formatDate(history.created_at)}</TableCell>
				</TableRow>

				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Box margin={1}>
								<Table size="small" aria-label="purchases">
									<TableHead>
										<TableRow>
											<TableCell>{t('history.key')}</TableCell>
											<TableCell>{t('history.new')}</TableCell>
											<TableCell>{t('history.old')}</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<Row
											data={history.data}
											oldData={history.oldData}
											dataKey={null}
											t={t}
										/>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</>
		)
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell></TableCell>
					<TableCell>{t('history.action')}</TableCell>
					<TableCell>{t('created_by')}</TableCell>
					<TableCell>{t('created_at')}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{histories.map((history, i) => (
					<HistoryTableRow key={`prompt-${i}`} history={history} t={t} />
				))}
			</TableBody>
		</Table>
	)
}

export default HistoryTable
