import { Box, Breadcrumbs, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Error from '../../../layouts/Main/components/Error'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import ApprovalsTable from './components/ApprovalsTable'

const Approvals = () => {
	const { t } = useTranslation()

	const [error, setError] = useState(false)

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('approvals')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('companies')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('approvals')}
				</Typography>
			</Breadcrumbs>

			<Box mt={4}>{error ? <Error /> : <ApprovalsTable setError={setError} />}</Box>
		</>
	)
}

export default Approvals
