export const findAddressByType = (type, addresses) => {
	if (!addresses?.length) return null
	return addresses.find((address) => address.address_type?.toLowerCase() === type.toLowerCase())
}

export const getAddressString = (type, addresses) => {
	const address = findAddressByType(type, addresses)

	if (!address) return null

	return [address.street, address.postal_code, address.city]
		.filter((v) => v !== undefined && v !== '')
		.join(', ')
}

export const getPostalAddress = (addresses) => {
	addresses = addresses
		? addresses.map((address) =>
				[address.street, address.postal_code, address.city].filter((str) => !!str).join(', ')
		  )
		: []

	return addresses.join(' / ')
}

export const getCountry = (type, addresses) => {
	const address = findAddressByType(type, addresses)

	if (!address) return null

	try {
		const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })

		return regionNames.of(address.country)
	} catch (e) {
		return address.country
	}
}
