import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'

import formatDateNew from '../../../utils/formatDateNew'

import snakecaseKeys from 'snakecase-keys'

import axios from '../../../utils/axios'
import { BASE_API_URL } from '../../../config/constants'

import useStyles from './CompanyList.styles'

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	InputBase,
	IconButton,
	TableSortLabel,
	Button,
	Grid
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { PAGE_SIZE, ORDER_DIRS } from '../../../config/constants'

const CompanyListTable = ({ setError }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const classes = useStyles()

	const [companies, setCompanies] = useState([])
	const [orderBy, setOrderBy] = useState('created_at')
	const [orderDir, setOrderDir] = useState('desc')
	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		loadMore()
	}, [offset, orderBy, orderDir, keyword])

	const loadMore = () => {
		setLoading(true)
		setLoadFinish(false)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
				order: "created_at.desc",
			},
			{ deep: true }
		)

		axios
			.get(`${BASE_API_URL}/v3/company`, { params })
			.then(({ data }) => {
				let temp = companies
				temp = temp.concat(data)
				setCompanies(temp)
				setLoading(false)
				if (data.length < PAGE_SIZE) setLoadFinish(true)
			})
			.catch((e) => {
				setError(true)
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setCompanies([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) setKeyword(value)
		else if (!offset) {
			loadMore()
		}
		resetSearch()
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	const gotoResultDetail = (id) => {
		navigate(`/companies/${id}`)
	}

	const headCells = [
		{ id: 'name', company: true, label: t('company_name'), width: 40 },
		{ id: 'status', label: t('status'), width: 20},
		{ id: 'business_id', label: t('businessId'), width: 20 },
		{ id: 'created_at', label: t('date'), width: 20 }
	]

	return (
		<>
			<Paper
				elevation={1}
				component="form"
				className={classes.root}
				onSubmit={handleSearchFormSubmit}
			>
				<InputBase
					placeholder={t('company.search.placeholder')}
					inputProps={{ 'aria-label': 'search google maps' }}
				/>
				<IconButton type="submit" className="iconButton" aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
			<TableContainer component={Paper}>
				<Table aria-label="Companies Table">
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.uuid}
									sortDirection={orderBy === headCell.uuid ? orderDir : false}
									width={`${headCell.width}%`}
								>
									<TableSortLabel
										active={orderBy === headCell.uuid}
										direction={orderBy === headCell.uuid ? orderDir : 'asc'}
										onClick={() => {
											handleSortChange(headCell.uuid, headCell.company)
										}}
									>
										{headCell.label}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{companies.map((result, i) => (
							<TableRow
								className={classes.tableRow}
								key={`result-table-row-${i}`}
								onClick={() => gotoResultDetail(result.uuid)}
							>
								<TableCell>{result.name}</TableCell>
								<TableCell>{result.status}</TableCell>
								<TableCell>{result.primary_business_id}</TableCell>

								<TableCell>{formatDateNew(result.created_at)}</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={4}>
									<Grid
										container
										justifyContent="center"
										direction="column"
										alignItems="center"
									>
										{loading && <LinearProgress className={classes.progress} />}
										<Button
											disabled={loading}
											variant="outlined"
											color="primary"
											onClick={() => setOffset(offset + PAGE_SIZE)}
										>
											{t('load_more')}
										</Button>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default CompanyListTable
