import {
	Box,
	Breadcrumbs,
	Button,
	Grid,
	IconButton,
	InputBase,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Error from '../../../layouts/Main/components/Error'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import axios from '../../../utils/axios'

import {BASE_API_URL, ORDER_DIRS, PAGE_SIZE} from '../../../config/constants'
import formatDate from '../../../utils/formatDate'
import { useNavigate } from 'react-router'
import SearchIcon from '@material-ui/icons/Search'
import snakecaseKeys from 'snakecase-keys'
import useStyles from './Organisations.styles'

const Organisations = () => {
	const classes = useStyles()

	const { t } = useTranslation()

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(false)

	const [orderBy, setOrderBy] = useState('name')
	const [orderDir, setOrderDir] = useState(ORDER_DIRS.ASC)

	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	const navigate = useNavigate()

	const headCells = [
		{ id: 'name', label: t('name') },
		{ id: 'email', label: t('email') },
		{ id: 'subscription', label: t('organisation.subscription') },
		{ id: 'created_at', label: t('createdAt') }
	]

	useEffect(() => {
		loadOrganisations()
	}, [offset, orderBy, orderDir, keyword])

	const loadOrganisations = () => {
		setLoading(true);
		setLoadFinish(false);

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset,
			},
			{ deep: true }
		);

		axios
			.get(`${BASE_API_URL}/v3/organization`, {
				params
			})
			.then(({ data }) => {
				setResults((prevResults) => prevResults.concat(data)); // Concatenate new data with existing results
				if (data.length < PAGE_SIZE) setLoadFinish(true);
			})
			.catch((e) => {
				setError(true);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const handleDetailNavigate = (id) => (e) => {
		navigate(`/organisations/${id}`)
	}

	const resetSearch = () => {
		setOffset(0)
		setResults([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) {
			setKeyword(value)
		} else if (!offset) {
			loadOrganisations()
		}
		resetSearch()
	}

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('organisations')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('companies')}
				</Link>
				<Typography variant={'subtitle2'} color="textPrimary">
					{t('organisations')}
				</Typography>
			</Breadcrumbs>

			<Box mt={4}>
				<Paper
					elevation={1}
					component="form"
					className={classes.searchRoot}
					onSubmit={handleSearchFormSubmit}
				>
					<InputBase
						placeholder={t('organisation.search.placeholder')}
						inputProps={{ 'aria-label': 'search google maps' }}
					/>
					<IconButton type="submit" className="iconButton" aria-label="search">
						<SearchIcon />
					</IconButton>
				</Paper>
			</Box>

			<Box mt={4}>
				{error ? (
					<Error />
				) : loading ? (
					<LinearProgress />
				) : (
					<TableContainer component={Paper}>
						<Table aria-label="Results Table">
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											sortDirection={
												orderBy === headCell.id ? orderDir : false
											}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={
													orderBy === headCell.id
														? orderDir
														: ORDER_DIRS.ASC
												}
												onClick={() => {
													handleSortChange(headCell.id, headCell.company)
												}}
											>
												{t(headCell.label)}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{results.map((result, i) => (
									<TableRow
										key={`result-table-row-${i}`}
										onClick={handleDetailNavigate(result.uuid)}
										className={classes.tableRow}
									>
										<TableCell>{result.name}</TableCell>
										<TableCell>{result.email}</TableCell>
										<TableCell>{result.subscription}</TableCell>
										<TableCell>{result.created_at}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								{!loadFinished && (
									<TableRow>
										<TableCell colSpan={4}>
											<Grid
												container
												justifyContent="center"
												direction="column"
												alignItems="center"
											>
												{loading && <LinearProgress />}
												<Button
													disabled={loading}
													variant="outlined"
													color="primary"
													onClick={() => setOffset(offset + PAGE_SIZE)}
												>
													{t('load_more')}
												</Button>
											</Grid>
										</TableCell>
									</TableRow>
								)}
							</TableFooter>
						</Table>
					</TableContainer>
				)}
			</Box>
		</>
	)
}

export default Organisations
