import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

import {
	Box,
	LinearProgress,
	Typography,
	Breadcrumbs,
	Table,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper,
	CardHeader,
	Card,
	Divider,
	TableHead
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Link, useParams } from 'react-router-dom'

import axios from '../../../utils/axios'
import { BASE_API_URL } from '../../../config/constants'

import Error from '../../../layouts/Main/components/Error'
import formatDateNew from '../../../utils/formatDateNew'
import QuotaForm from './components/QuotaForm'

const Organisation = () => {
	const params = useParams()

	const { t } = useTranslation()

	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(false)

	const [organisation, setOrganisation] = useState({})

	const [planType, setPlanType] = useState('')

	const [customQuota, setCustomQuota] = useState({})

	const [quotaData, setQuotaData] = useState({})
	const [quotaUsage, setQuotaUsage] = useState({})


	useEffect(() => {
		;(async () => {
			setLoading(true)

			try {
				const { data } = await axios.get(`${BASE_API_URL}/v3/organization`, {
					params: {
						uuid: "eq." + params.id
					}
				})
				setOrganisation(data[0])

				setPlanType(data[0].subscription || '')

				await fetchQuota()
			} catch (e) {
				setError(true)
			}

			setLoading(false)
		})()
	}, [])

	const fetchQuota = async () => {
		try {
			const { data: quotaData } = await axios.get(
				`${BASE_API_URL}/v3/organization_quotas`,
				{
					params: {
						uuid: "eq." + params.id
					}
				}
			);
			setQuotaData(quotaData || []);

			const { data: usageData } = await axios.get(
				`${BASE_API_URL}/v3/organization_usage`,
				{
					params: {
						uuid: "eq." + params.id
					}
				}
			);
			setQuotaUsage(usageData || []);

			const { data: availableQuotasData } = await axios.get(
				`${BASE_API_URL}/v3/api_endpoint`
			);

			const tmp = {};
			for (const quota of availableQuotasData) {
				const name = quota.name
					.replace(/([A-Z])\w+\s(v2|\/)+/g, "")
					.replaceAll("/", "_")
					.toUpperCase();
				tmp[name] =
					quotaData.find((value) => value.api_endpoint === quota.path)
						?.allocated_quota || 0;
			}
			setCustomQuota(tmp);
		} catch (error) {
			// Handle error, e.g., set error state
			setError(true);
		}
	}

	return (
		<>
			<Box mt={2}>
				<Typography variant="h4" component="h2" gutterBottom>
					{t('organisation')}
				</Typography>
			</Box>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('companies')}
				</Link>

				<Link variant={'subtitle2'} color="textPrimary" to={'/organisations'}>
					{t('organisations')}
				</Link>

				{organisation && (
					<Typography variant={'subtitle2'} color="textPrimary">
						{organisation.name}
					</Typography>
				)}
			</Breadcrumbs>

			<Box mt={4}>
				{error ? (
					<Error />
				) : loading ? (
					<LinearProgress />
				) : (
					<Box mb={6} display={'flex'} flexDirection={'column'} gridRowGap={30}>
						<TableContainer>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell width={'20%'} variant={'head'}>
											{t('name')}
										</TableCell>
										<TableCell>{organisation.name}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>{t('email')}</TableCell>
										<TableCell>{organisation.email}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>{t('enabled')}</TableCell>
										<TableCell>
											{organisation.enabled ? t('enabled') : t('disabled')}
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>
											{t('organisation.amlEnabled')}
										</TableCell>
										<TableCell>
											{organisation.aml_enabled ? t('enabled') : t('disabled')}
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>
											{t('organisation.approvalEnabled')}
										</TableCell>
										<TableCell>
											{organisation.approval_enabled
												? t('enabled')
												: t('disabled')}
										</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>
											{t('organisation.subscription')}
										</TableCell>
										<TableCell>{organisation.subscription}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>{t('modified_at')}</TableCell>
										<TableCell>{organisation.modified_at}</TableCell>
									</TableRow>

									<TableRow>
										<TableCell variant={'head'}>{t('createdAt')}</TableCell>
										<TableCell>{organisation.createdAt}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						{quotaData && !!quotaData.length && (
							<Card component={Paper}>
								<CardHeader title={t('organisation.quotaData')} />
								<Divider />
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												{Object.keys(quotaData[0]).map((key, index) => (
													<React.Fragment key={index}>
														{key !== 'id' ? (
															<TableCell>
																{t(`quota.${key}`)}
															</TableCell>
														) : (
															<></>
														)}
													</React.Fragment>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{quotaData.map((item, index) => (
												<TableRow key={index}>
													{Object.keys(quotaData[0]).map(
														(key, keyIndex) => (
															<React.Fragment key={keyIndex}>
																{key !== 'id' ? (
																	<TableCell>
																		{item[key]}
																	</TableCell>
																) : (
																	<></>
																)}
															</React.Fragment>
														)
													)}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Card>
						)}

						{quotaUsage && !!quotaUsage.length && (
							<Card component={Paper}>
								<CardHeader title={t('organisation.quotaUsage')} />
								<Divider />
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												{Object.keys(quotaUsage[0]).map((key, index) => (
													<React.Fragment key={index}>
														{key !== 'uuid' ? (
															<TableCell>
																{t(`quota.${key}`)}
															</TableCell>
														) : (
															<></>
														)}
													</React.Fragment>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{quotaUsage.map((item, index) => (
												<TableRow key={index}>
													{Object.keys(quotaUsage[0]).map(
														(key, keyIndex) => (
															<React.Fragment key={keyIndex}>
																{key !== 'uuid' ? (
																	<TableCell>
																		{item[key]}
																	</TableCell>
																) : (
																	<></>
																)}
															</React.Fragment>
														)
													)}
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Card>
						)}

						<QuotaForm
							planType={planType}
							customQuota={customQuota}
							setCustomQuota={setCustomQuota}
							organisation={organisation}
							setOrganisation={setOrganisation}
							setPlanType={setPlanType}
							fetchQuota={fetchQuota}
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export default Organisation
