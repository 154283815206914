import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ApprovalsTablePromptIdentification = ({ data }) => {
	const { t } = useTranslation()

	return (
		<Box>
			<Typography variant="h6" gutterBottom component="div">
				{t('approvals.promptIdentification')}
			</Typography>
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell width={'30%'} variant={'th'}>
							{t('approvals.areTosAccepted')}
						</TableCell>
						<TableCell>{data.are_tos_accepted}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'th'}>{t('approvals.eidentState')}</TableCell>
						<TableCell>{data.eident_state}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('approvals.expectedFullName')}</TableCell>
						<TableCell>{data.expected_full_name}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('approvals.expectedSsn')}</TableCell>
						<TableCell>{data.expected_ssn}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('approvals.userFullName')}</TableCell>
						<TableCell>{data.user_full_name}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('approvals.userSsn')}</TableCell>
						<TableCell>{data.user_ssn}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('approvals.promptId')}</TableCell>
						<TableCell>{data.prompt_id}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('completed_at')}</TableCell>
						<TableCell>{data.completed_at}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'th'}>{t('created_at')}</TableCell>
						<TableCell>{data.created_at}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	)
}

export default ApprovalsTablePromptIdentification
