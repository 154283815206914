import { useEffect, useState } from 'react'

import axios from '../../../utils/axios'
import {BASE_API_URL} from '../../../config/constants'

import useStyles from './DecisionList.styles'

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	Breadcrumbs,
	Box,
	TableSortLabel,
	Grid,
	Button,
	InputBase,
	IconButton
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import DecisionListRow from './DecisionListRow'

const DecisionList = () => {
	const { t } = useTranslation()

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const [loadFinished, setLoadFinished] = useState(false)
	const [page, setPage] = useState(0)
	const [filters, setFilters] = useState({
		orderBy: 'created_at',
		order: 'desc',
		rowsPerPage: 20,
		keyword: ''
	})

	const classes = useStyles()

	useEffect(() => {
		loadMore()
	}, [filters])

	const loadMore = () => {
		setLoading(true)
		axios
			.get(`${BASE_API_URL}/v3/decision_report`, {
				params: {
					offset: page * filters.rowsPerPage,
					limit: filters.rowsPerPage,
					order: 'created_at.desc',
				}
			})
			.then(({ data }) => {
				setResults(results.concat(data))

				setPage(page + 1)

				if (data.length < filters.rowsPerPage) {
					setLoadFinished(true)
				}
			})
			.catch((e) => {
				setError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const sortResults = (field, company = false) => {
		setResults([])

		let order = filters.order
		if (filters.orderBy === field) {
			if (order === 'desc') {
				order = 'asc'
			} else {
				order = 'desc'
			}
		} else {
			order = 'asc'
		}

		setPage(0)
		setFilters({ ...filters, order: order, orderBy: field })
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0] ? event.target[0].value : event.target.value
		if (value !== filters.keyword) {
			setPage(0)
			setResults([])
			setFilters({
				...filters,
				keyword: value
			})
		}
	}

	const headCells = [
		{ id: 'decision_name', company: true, label: t('decision.name'), width: 13.3 },
		{ id: 'company_name', company: true, label: t('company_name'), width: 13.3 },
		{ id: 'person_name', label: t('person_name'), width: 13.3 },
		{ id: 'result', label: t('result'), width: 12 },
		{ id: 'organization_id', label: t('organizationId') },
		{ id: 'created_by', label: t('created_by'), width: 12 },
		{ id: 'created_at', label: t('date'), width: 12 }
	]

	return (
		<>
			<Typography variant={'h3'} gutterBottom>
				{t('nav.decisions')}
			</Typography>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<Link color="inherit" variant={'subtitle2'} to="/">
					{t('nav.companies')}
				</Link>
				<Typography color="textPrimary" variant={'subtitle2'}>
					{t('nav.decisions')}
				</Typography>
			</Breadcrumbs>

			<Box mt={4}>
				{error ? (
					<Error />
				) : (
					<>
						<Paper
							elevation={1}
							component="form"
							className={classes.root}
							onSubmit={handleSearchFormSubmit}
						>
							<InputBase
								placeholder={t('company.search.placeholder')}
								inputProps={{ 'aria-label': 'search google maps' }}
								onBlur={handleSearchFormSubmit}
							/>
							<IconButton type="submit" className="iconButton" aria-label="search">
								<SearchIcon />
							</IconButton>
						</Paper>

						<TableContainer component={Paper}>
							<Table aria-label="Results Table">
								<TableHead>
									<TableRow>
										{headCells.map((headCell) => (
											<TableCell
												width={`${headCell.width}%`}
												key={headCell.uuid}
												sortDirection={
													filters.orderBy === headCell.uuid
														? filters.order
														: false
												}
											>
												<TableSortLabel
													active={filters.orderBy === headCell.uuid}
													direction={
														filters.orderBy === headCell.uuid
															? filters.order
															: 'desc'
													}
													onClick={() => {
														sortResults(headCell.uuid, headCell.company)
													}}
												>
													{headCell.label}
												</TableSortLabel>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{results.map((result, i) => (
										<DecisionListRow
											result={result}
											key={`result-table-row-${i}`}
										/>
									))}

									{loading && (
										<TableRow>
											<TableCell colSpan={headCells.length + 1}>
												<LinearProgress />
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								<TableFooter>
									{!loadFinished && (
										<TableRow>
											<TableCell colSpan={headCells.length + 1}>
												<Grid
													container
													justifyContent="center"
													direction="column"
													alignItems="center"
												>
													<Button
														disabled={loading}
														variant="outlined"
														color="primary"
														onClick={loadMore}
													>
														{t('load_more')}
													</Button>
												</Grid>
											</TableCell>
										</TableRow>
									)}
								</TableFooter>
							</Table>
						</TableContainer>
					</>
				)}
			</Box>
		</>
	)
}

export default DecisionList
