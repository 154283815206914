import {
	Box,
	Breadcrumbs,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Error from '../../../layouts/Main/components/Error'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import axios from '../../../utils/axios'

import { BASE_API_URL } from '../../../config/constants'
import useStyles from './ApprovalTemplates.styles'
import { useNavigate } from 'react-router'

const ApprovalTemplates = () => {
	const classes = useStyles()

	const { t } = useTranslation()

	const navigate = useNavigate()

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)

	const [loading, setLoading] = useState(false)

	const headCells = [
		{ id: 'approval_theme', label: t('approval.approvalTheme') },
		{ id: 'from_address', label: t('approval.fromAddress') },
		{ id: 'from_name', label: t('approval.fromName') },
		{ id: 'country', label: t('country') },
		{ id: 'identification_template', label: t('approval.identificationTemplate') },
		{ id: 'returning_customer_template', label: t('approval.returningCustomerTemplate') },
		{ id: 'organization_id', label: t('organisation') }
	]

	useEffect(() => {
		;(async () => {
			setLoading(true)

			axios
				.get(`${BASE_API_URL}/v3/approval_template_configuration`, {})
				.then(({ data }) => {
					setResults(data)
				})
				.catch((e) => {
					setError(true)
				})
				.finally(() => {
					setLoading(false)
				})
		})()
	}, [])

	const handleDetailNavigate = (id) => (e) => {
		navigate(`/approval-templates/${id}`)
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h3" component="h3" gutterBottom={true}>
					{t('approval.templates')}
				</Typography>
			</Box>

			<Box display={'flex'} justifyContent={'space-between'}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Link color="inherit" variant={'subtitle2'} to="/">
						{t('companies')}
					</Link>
					<Typography variant={'subtitle2'} color="textPrimary">
						{t('approval.templates')}
					</Typography>
				</Breadcrumbs>

				<Link to={'/approval-templates/create'}>{t('approval.templates.create')}</Link>
			</Box>

			<Box mt={4}>
				{error ? (
					<Error />
				) : (
					<TableContainer component={Paper}>
						<Table aria-label="Results Table">
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell key={headCell.id}>{t(headCell.label)}</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{results.map((result, i) => (
									<TableRow
										key={`result-table-row-${i}`}
										className={classes.tableRow}
										onClick={handleDetailNavigate(result.id)}
									>
										<TableCell>{result.approval_theme}</TableCell>
										<TableCell>{result.from_address}</TableCell>
										<TableCell>{result.from_name}</TableCell>
										<TableCell>{result.country}</TableCell>
										<TableCell>{result.identification_template}</TableCell>
										<TableCell>{result.returning_customer_template}</TableCell>
										<TableCell>
											<Link
												to={`/organisations/${result.organization_id?.uuid}`}
											>
												{result.organization_id}
											</Link>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								{loading && (
									<TableRow>
										<TableCell colSpan={headCells.length}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								)}
							</TableFooter>
						</Table>
					</TableContainer>
				)}
			</Box>
		</>
	)
}

export default ApprovalTemplates
