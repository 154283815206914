import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './layouts/Main'

// import { ApiKeyList } from './pages/api-key'

import { Login } from './pages/login'
import { SmartFormList, SmartFormDetail } from './pages/smart-form'
import { CompanyList, CompanyDetail, PersonDetail } from './pages/company'
import { DecisionList, DecisionDetail } from './pages/decision'
import { Profile } from './pages/profile'
import { Organisation } from './pages/organisation'
import { Admin } from './pages/admin'
import { Organisations } from './pages/organisations'
import { Api } from './pages/api'
import { EmailDomains } from './pages/email-domains'
import { NotFound } from './pages/not-found'
import { RequireAuth } from './utils/RequireAuth'
import { UserOrganisation } from './pages/user-organisation'
import { Dashboard } from './pages/dashboard'
import { OrganisationCreate } from './pages/organisation-create'
import Approvals from './pages/approval/Approvals'
import ApprovalTemplates from './pages/approval/ApprovalTemplates'
import { ApprovalTemplateDetail } from './pages/approval'

const AppRouter = () => (
	<Routes>
		<Route path="/login" element={<Login />} />

		<Route element={<Main />}>
			<Route element={<RequireAuth />}>
				<Route path="/" element={<CompanyList />} />
				<Route path="/companies/:id" element={<CompanyDetail />} />

				<Route path="/dashboard" element={<Dashboard />} />

				<Route path="/companies/:id/:personId" element={<PersonDetail />} />

				<Route path="/results" element={<DecisionList />} />
				<Route path="/results/:id" element={<DecisionDetail />} />

				<Route path="/smart-forms" element={<SmartFormList />} />
				<Route path="/smart-forms/:id" element={<SmartFormDetail />} />

				<Route path="/profile" element={<Profile />} />

				<Route path="/organisation" element={<UserOrganisation />} />

				<Route path="/create-organisation" element={<OrganisationCreate />} />

				<Route path="/approvals" element={<Approvals />} />
				<Route path="/approval-templates" element={<ApprovalTemplates />} />
				<Route path="/approval-templates/create" element={<ApprovalTemplateDetail />} />
				<Route path="/approval-templates/:id" element={<ApprovalTemplateDetail />} />

				<Route path="/organisations" element={<Organisations />} />
				<Route path="/organisations/:id" element={<Organisation />} />

				<Route path="/admin" element={<Admin />} />

				<Route path="/domains" element={<EmailDomains />} />
				<Route path="/api" element={<Api />} />
			</Route>
		</Route>

		<Route path="*" element={<NotFound />}></Route>

		{/* <Route   path="/api-keys" element={<ApiKeyList />} /> */}
	</Routes>
)

export default AppRouter
