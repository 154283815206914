import { Table, TableBody, TableRow, TableCell, Chip, CardHeader, Divider } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import useStyles from './DecisionApiTableRow.styles'

const DecisionTableTableRow = ({ decisionApi }) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const [decisionResult, setDecisionResult] = useState(null)

	useEffect(() => {
		try {
			setDecisionResult(JSON.parse(decisionApi.result))
		} catch (e) {
			setDecisionResult(decisionApi.result)
		}
	}, [decisionApi])

	const NestedObjectRow = (props) => {
		const { data } = props

		return (
			<>
				{data && typeof data === 'object' ? (
					<>
						{Object.keys(data).map((key) => (
							<TableRow key={key}>
								<TableCell component="th" scope="row">
									{t('decision.result.' + key)}
								</TableCell>

								{data[key] && typeof data[key] === 'object' ? (
									<NestedObjectRow data={data[key]} />
								) : (
									<TableCell>{parseValue(data[key])}</TableCell>
								)}
							</TableRow>
						))}
					</>
				) : (
					<DecisionTableRow name={'result'} value={data} />
				)}
			</>
		)
	}

	const parseValue = (value) => {
		return typeof value === 'boolean' ? value.toString() : value
	}

	const DecisionTableRow = ({ name, value }) => {
		return value ? (
			<TableRow className={classes.tableRow}>
				<TableCell width={'50%'}>{t(name)}</TableCell>
				<TableCell>{parseValue(value)}</TableCell>
			</TableRow>
		) : (
			<></>
		)
	}

	const isEligible = () => {
		return decisionResult && typeof decisionResult === 'object' ? decisionResult.result : null
	}

	return (
		<>
			{decisionApi && decisionApi.decisionName && (
				<>
					<CardHeader
						title={decisionApi.decisionName}
						action={
							isEligible() !== null && (
								<Chip
									className={
										classes.chip +
										' ' +
										(isEligible() ? classes.chip : classes.chipDanger)
									}
									label={
										isEligible()
											? t('decision.eligible')
											: t('decision.not_eligible')
									}
								/>
							)
						}
						component={Link}
						to={`/results/${decisionApi.id}`}
					/>

					<Divider />

					<Table aria-label="Decision Api Table">
						<TableBody>
							<DecisionTableRow
								name={'company_name'}
								value={decisionApi.companyName}
							/>
							<DecisionTableRow
								name={'name'}
								value={
									decisionApi.firstName
										? `${decisionApi.firstName} ${decisionApi.lastName}`
										: null
								}
							/>
							<DecisionTableRow name={'email'} value={decisionApi.personEmail} />

							{decisionResult && <NestedObjectRow data={decisionResult} />}
						</TableBody>
					</Table>
				</>
			)}
		</>
	)
}

export default DecisionTableTableRow
