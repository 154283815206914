import {
	Card,
	CardHeader,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const CompanyContactInfo = ({ addresses, titleKey, type }) => {
	const { t } = useTranslation()

	const [filteredAddresses, setFilteredAddresses] = useState([])

	useEffect(() => {
		setFilteredAddresses(
			addresses?.filter((address) => address.finvoiceAddressType?.toLowerCase() === type)
		)
	}, [addresses, type])

	return (
		<>
			{filteredAddresses && filteredAddresses.length > 0 && (
				<Card component={Paper}>
					<CardHeader title={t(`company.invoicing.address.${titleKey}`)} />

					<Divider />

					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t('company.invoicing.address')}</TableCell>
									<TableCell>{t('company.invoicing.name')}</TableCell>
									<TableCell>{t('company.invoicing.operator')}</TableCell>
									<TableCell>{t('company.invoicing.operatorCode')}</TableCell>
									<TableCell>{t('company.invoicing.ovt')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{filteredAddresses.map((address, i) => (
									<TableRow key={i}>
										<TableCell>{address.address}</TableCell>
										<TableCell>{address.name}</TableCell>
										<TableCell>{address.operator}</TableCell>
										<TableCell>{address.operatorCode}</TableCell>
										<TableCell>{address.ovt}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Card>
			)}
		</>
	)
}

export default CompanyContactInfo
