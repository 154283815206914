import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Collapse,
	Divider,
	IconButton,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import axios from '../../../../utils/axios'
import { COMPANY_API_URL } from '../../../../config/constants'
import { SnackbarContext } from '../../../../context'

import formatDate from '../../../../utils/formatDate'
import TableCellWithTooltip from '../../../../components/shared/TableCellWithTooltop'
import formatCurrency from '../../../../utils/formatCurrency'

import useStyles from './styles'

const CompanyKeyFigures = ({
	businessId,
	data,
	afterDataRefresh,
	refreshing,
	setRefreshing,
	isConsolidated,
	country
}) => {
	const classes = useStyles()

	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [open, setOpen] = useState(true)

	const [loadingItem, setLoadingItem] = useState(false)
	const [tableData, setTableData] = useState(null)

	const maxYears = 5

	const groups = [
		{
			key: 'background',
			valueKeys: [
				'turnover',
				'fiscalYearResultPct',
				'changeOfTurnover',
				'changeOfBalanceTotal',
				'changeOfEmployeeFigure'
			]
		},
		{
			key: 'viability',
			valueKeys: [
				'operatingResultPct',
				'netResultPct',
				'returnOnEquityPct',
				'returnOnInvestementsPct',
				'returnOnAssetsPct'
			]
		},
		{
			key: 'solvency',
			valueKeys: ['equityRatioPct', 'netDebtRatioPct']
		},
		{
			key: 'liquidity',
			valueKeys: ['quickRatio', 'currentRatio']
		}
	]

	useEffect(() => {
		if (data) {
			let tmp = [
				data.keyFigures,
				...data.keyFiguresHistory.sort(
					(a, b) =>
						new Date(a.fiscalPeriodEndDate).getFullYear() <
						new Date(b.fiscalPeriodEndDate).getFullYear()
				)
			]

			tmp = tmp.filter((data) => data.consolidated === (isConsolidated ? true : false))

			setTableData(tmp.slice(0, maxYears))
		} else {
			setTableData(null)
		}
	}, [data])

	const handleRefresh = async () => {
		setLoadingItem(true)
		setRefreshing(true)

		try {
			await axios.get(`${COMPANY_API_URL}/figures`, {
				params: {
					businessId: businessId,
					refresh: true,
					country: country
				}
			})
			await afterDataRefresh()
		} catch (error) {
			showMessage(t('error'), 'error')
		}

		setLoadingItem(false)
	}

	const formatValue = (key, value) => {
		if (typeof value !== 'undefined' && typeof value !== 'object') {
			if (key === 'turnover') {
				return formatCurrency(value)
			} else if (key === 'employee_costs12_months') {
				return new Intl.NumberFormat('fi-FI', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0
				}).format(value)
			} else if (key.indexOf('pct') > -1) {
				value = value.toString()
				return value + '%'
			} else if (key === 'fiscalPeriodEndDate') {
				return formatDate(value, false)
			} else if (
				key === 'fiscalYearResultPct' ||
				key === 'changeOfTurnover' ||
				key === 'changeOfBalanceTotal' ||
				key === 'changeOfEmployeeFigure'
			) {
				return `${value}%`
			} else {
				return value.toString()
			}
		}

		return value
	}

	const KeyTableRow = ({ keys, tableData, formatValue }) => {
		return (
			<>
				{keys.map((key, index) => {
					return (
						<TableRow key={`key-figure-row-${index}`}>
							<TableCell variant={'head'} width="40%">
								<TableCellWithTooltip
									title={`company.key_figures.${key}`}
									info={`company.key_figures.${key}.info`}
								/>
							</TableCell>

							{tableData.map((data, index) => (
								<TableCell key={index}>{formatValue(key, data[key])}</TableCell>
							))}
						</TableRow>
					)
				})}
			</>
		)
	}
	return (
		<>
			{(!isConsolidated || (isConsolidated && tableData && tableData.length > 0)) && (
				<Card component={Paper}>
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
						pr={2}
					>
						<CardHeader
							title={t(
								isConsolidated
									? 'company_key_figures_title.consolidated'
									: 'company_key_figures_title'
							)}
							action={
								<IconButton aria-label="collapse" onClick={() => setOpen(!open)}>
									{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
								</IconButton>
							}
						/>

						<Button
							color={'primary'}
							variant={'contained'}
							onClick={handleRefresh}
							disabled={loadingItem || refreshing}
						>
							{t('get')}
						</Button>
					</Box>

					{loadingItem ? (
						<LinearProgress />
					) : (
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Divider />

							{tableData && (
								<>
									<CardContent>
										<Table>
											<TableBody>
												{groups.map((group, groupIndex) => (
													<TableRow key={groupIndex}>
														<TableCell
															colSpan={2}
															className={classes.nestedTableCell}
														>
															<Table>
																{group.key && (
																	<TableHead>
																		<TableRow>
																			<TableCell>
																				<Typography
																					variant={'h5'}
																				>
																					{t(
																						`company.key_figures.group.${group.key}`
																					)}
																				</Typography>
																			</TableCell>

																			{tableData.map(
																				(
																					historyData,
																					index
																				) => (
																					<TableCell
																						key={index}
																					>
																						{new Date(
																							historyData.fiscalPeriodEndDate
																						).getFullYear()}
																					</TableCell>
																				)
																			)}
																		</TableRow>
																	</TableHead>
																)}
																<TableBody>
																	<KeyTableRow
																		keys={group.valueKeys}
																		tableData={tableData}
																		formatValue={formatValue}
																	/>
																</TableBody>
															</Table>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</CardContent>
								</>
							)}
						</Collapse>
					)}
				</Card>
			)}
		</>
	)
}

export default CompanyKeyFigures
