import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableSortLabel,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Button
} from '@material-ui/core'

import { BASE_API_URL, ORDER_DIRS } from '../../../config/constants'

import { SnackbarContext } from '../../../context'

const OrganisationTable = ({ results }) => {
	const { t } = useTranslation()

	const [orderedResults, setOrderedResults] = useState([])
	const [orderBy, setOrderBy] = useState('userName')
	const [orderDir, setOrderDir] = useState(ORDER_DIRS.ASC)

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const { showMessage } = useContext(SnackbarContext)

	const headCells = [
		{ id: 'userName', label: 'username' },
		{ id: 'email', label: 'email' },
		{ id: 'role', label: 'role' }
	]

	const getOnePage = () =>
		rowsPerPage > 0
			? orderedResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			: orderedResults

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	useEffect(() => {
		const sortResults = () => {
			let newResults

			const getField = (obj) => {
				return obj[orderBy] ? obj[orderBy].toLowerCase() : ''
			}
			newResults = results.sort((a, b) => {
				a = getField(a)
				b = getField(b)

				let res = 0
				if (a < b) {
					res = -1
				}
				if (a > b) {
					res = 1
				}

				if (orderDir === ORDER_DIRS.DESC) res *= -1

				return res
			})

			setOrderedResults([...newResults])
		}

		sortResults()
	}, [orderBy, orderDir, results])

	const sortResults = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)
	}

	const disableUser = (user) => {
		axios
			.patch(BASE_API_URL + '/v3/user_accounts', {
				uuid: "eq." + user.userId,
				enabled: false
			})
			.then((data) => {
				if (data.status === 200) {
					showMessage(t('user.update.success'), 'success')
				} else {
					showMessage(t('user.update.error'), 'error')
				}
			})
			.catch((error) => {
				showMessage(t('user.update.error'), 'error')
			})
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="Results Table">
					<TableHead>
						<TableRow>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={
											orderBy === headCell.id ? orderDir : ORDER_DIRS.ASC
										}
										onClick={() => {
											sortResults(headCell.id)
										}}
									>
										{t(headCell.label)}
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{getOnePage().map((result, i) => (
							<TableRow key={`result-table-row-${i}`}>
								<TableCell>{result.userName}</TableCell>
								<TableCell>{result.email}</TableCell>
								<TableCell>{result.role}</TableCell>
								<TableCell>
									<Button
										variant={'contained'}
										onClick={() => {
											disableUser(result)
										}}
									>
										{t('user.disable')}
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
								colSpan={3}
								count={orderedResults.length}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { 'aria-label': 'rows per page' },
									native: true
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}
export default OrganisationTable
