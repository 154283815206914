import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import camelcaseKeys from 'camelcase-keys'

import axios from '../../utils/axios'
import { INTERNAL_API_URL } from '../../config/constants'

import useStyles from './EmailDomains.styles'

import {
	Paper,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableFooter,
	LinearProgress,
	Breadcrumbs,
	Box,
	TableSortLabel,
	Grid,
	Button,
	InputBase,
	IconButton
} from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import Error from '../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import formatDate from '../../utils/formatDate'

const EmailDomains = () => {
	const { t } = useTranslation()

	const headCells = [
		{
			id: 'primary_business_id',
			company: true,
			label: t('company.primary_business_id'),
			width: 13.3,
			sortable: true
		},
		{ id: 'name', label: t('name'), width: 12, sortable: true },
		{ id: 'domain', label: t('domain'), width: 12, sortable: true },
		{ id: 'createdAt', label: t('createdAt'), width: 12, sortable: false },
		{ id: 'active', label: t('active'), width: 12, sortable: true }
	]

	const [results, setResults] = useState([])
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const [loadFinished, setLoadFinished] = useState(false)
	const [page, setPage] = useState(0)
	const [filters, setFilters] = useState({
		orderBy: 'created_at',
		order: 'desc',
		rowsPerPage: 20,
		keyword: ''
	})

	const classes = useStyles()

	useEffect(() => {
		loadMore()
	}, [filters])

	const loadMore = () => {
		setLoading(true)
		axios
			.get(`/global_email_domains`, {
				params: {
					order: `${filters.orderBy}.${filters.order}`,
					name: `ilike.*${filters.keyword}*`,
					offset: page * filters.rowsPerPage,
					limit: filters.rowsPerPage
				}
			})
			.then(({ data }) => {
				data = camelcaseKeys(data, { deep: true })
				setResults(results.concat(data))

				setPage(page + 1)

				if (data.length < filters.rowsPerPage) {
					setLoadFinished(true)
				}
			})
			.catch((e) => {
				setError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const sortResults = (field, company = false) => {
		setResults([])

		let order = filters.order
		if (filters.orderBy === field) {
			if (order === 'desc') {
				order = 'asc'
			} else {
				order = 'desc'
			}
		} else {
			order = 'asc'
		}

		setPage(0)
		setFilters({ ...filters, order: order, orderBy: field })
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0] ? event.target[0].value : event.target.value
		if (value !== filters.keyword) {
			setPage(0)
			setResults([])
			setFilters({
				...filters,
				keyword: value
			})
		}
	}

	return (
		<>
			<Typography variant={'h3'} gutterBottom>
				{t('nav.global_email_domains')}
			</Typography>

			<Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
				<RouterLink to="/">{t('nav.companies')}</RouterLink>
				<Typography color="textPrimary" variant={'subtitle2'}>
					{t('nav.global_email_domains')}
				</Typography>
			</Breadcrumbs>

			<Box mt={4}>
				{error ? (
					<Error />
				) : (
					<>
						<Paper
							elevation={1}
							component="form"
							className={classes.root}
							onSubmit={handleSearchFormSubmit}
						>
							<InputBase
								placeholder={t('domain.search.placeholder')}
								inputProps={{ 'aria-label': 'search' }}
								onChange={handleSearchFormSubmit}
							/>
							<IconButton type="submit" className="iconButton" aria-label="search">
								<SearchIcon />
							</IconButton>
						</Paper>

						<TableContainer component={Paper}>
							<Table aria-label="Emails Table">
								<TableHead>
									<TableRow>
										{headCells.map((headCell) => (
											<TableCell
												width={`${headCell.width}%`}
												key={headCell.id}
												sortDirection={
													filters.orderBy === headCell.id
														? filters.order
														: false
												}
											>
												{headCell.sortable ? (
													<TableSortLabel
														active={filters.orderBy === headCell.id}
														direction={
															filters.orderBy === headCell.id
																? filters.order
																: 'desc'
														}
														onClick={() => {
															sortResults(
																headCell.id,
																headCell.company
															)
														}}
													>
														{headCell.label}
													</TableSortLabel>
												) : (
													<>{headCell.label}</>
												)}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{results.map((result, i) => (
										<TableRow key={`result-table-row-${i}`}>
											<TableCell>{result.primaryBusinessId}</TableCell>
											<TableCell>{result.name}</TableCell>
											<TableCell>{result.domain}</TableCell>
											<TableCell>
												{formatDate(result.createdAt, false)}
											</TableCell>
											<TableCell>
												{result.active ? 'true' : 'false'}
											</TableCell>
										</TableRow>
									))}
									{loading && (
										<TableRow>
											<TableCell colSpan={headCells.length + 1}>
												<LinearProgress />
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								<TableFooter>
									{!loadFinished && (
										<TableRow>
											<TableCell colSpan={headCells.length + 1}>
												<Grid
													container
													justifyContent="center"
													direction="column"
													alignItems="center"
												>
													<Button
														disabled={loading}
														variant="outlined"
														color="primary"
														onClick={loadMore}
													>
														{t('load_more')}
													</Button>
												</Grid>
											</TableCell>
										</TableRow>
									)}
								</TableFooter>
							</Table>
						</TableContainer>
					</>
				)}
			</Box>
		</>
	)
}

export default EmailDomains
