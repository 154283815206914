import React, { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import { Authenticator, useAuthenticator, ThemeProvider } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import { Box, Toolbar, useTheme } from '@material-ui/core'
import AppHeader from '../../layouts/Main/components/AppHeader'
import Logo from '../../layouts/Main/components/HeaderContent/Logo'
import { Auth } from 'aws-amplify'

import useStyles from './Login.styles'

const Login = () => {
	const classes = useStyles()

	const { route } = useAuthenticator((context) => [context.route])
	const { user } = useAuthenticator((context) => [context.user])

	const navigate = useNavigate()
	const location = useLocation()
	const { setUser } = useContext(UserContext)

	let from = location.state?.from?.pathname || '/'

	const materialTheme = useTheme()

	const authTheme = {
		name: 'Auth Theme',
		tokens: {
			colors: {
				brand: {
					primary: {
						10: 'transparent',
						20: 'transparent',
						30: materialTheme.palette.primary.light,
						40: materialTheme.palette.primary.light,
						50: materialTheme.palette.primary.main,
						60: materialTheme.palette.primary.main,
						70: materialTheme.palette.primary.main,
						80: materialTheme.palette.primary.main,
						90: materialTheme.palette.primary.main,
						100: materialTheme.palette.primary.main
					}
				},
				shadow: {
					primary: materialTheme.palette.grey.A100,
					secondary: materialTheme.palette.grey.A100,
					tertiary: materialTheme.palette.grey.A100
				},
				border: {
					primary: materialTheme.palette.grey.A100,
					secondary: materialTheme.palette.grey.A100,
					tertiary: materialTheme.palette.grey.A100
				},
				font: {
					primary: materialTheme.palette.text.primary,
					secondary: materialTheme.palette.text.primary,
					tertiary: materialTheme.palette.text.hint,
					disabled: materialTheme.palette.text.disabled
				}
			},
			fonts: {
				default: {
					variable: { value: materialTheme.typography.fontFamily },
					static: { value: materialTheme.typography.fontFamily }
				}
			}
		}
	}

	useEffect(() => {
		if (route === 'authenticated') {
			console.log('from', from)
			navigate(from, { replace: true })
		}
	}, [route, navigate, from])

	useEffect(() => {
		handleAuthStateChange('signedin', user)
	}, [user])

	const handleAuthStateChange = (authState, authData) => {
		if (authState === 'signedin' && authData && authData.signInUserSession) {
			const {
				username,
				attributes: { email, 'custom:org': organizationId },
				signInUserSession: {
					idToken: {
						payload: { 'cognito:groups': groups }
					}
				}
			} = authData

			const internal = groups.indexOf('internal') > -1
			if (!internal) {
				Auth.signOut()
				setUser(null)
				navigate('/login')

				return
			}

			setUser({
				username,
				email,
				organizationId,
				groups
			})
		} else if (authState === 'signedout' && location.pathname !== '/login') {
			navigate('/login')
		}
	}

	return (
		<>
			<AppHeader>
				<Toolbar>
					<Logo />
				</Toolbar>
			</AppHeader>
			<Box className={classes.wrapper}>
				<ThemeProvider theme={authTheme}>
					<Box py={10}>
						<Authenticator hideSignUp={true}></Authenticator>
					</Box>
				</ThemeProvider>
			</Box>
		</>
	)
}

export default Login
