import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	chip: {
		background: theme.palette.success.main,
		color: theme.palette.primary.contrastText,
		textTransform: 'capitalize',
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	chipDanger: {
		background: theme.palette.error.main
	},
	tableRow: {
		cursor: 'pointer'
	}
}))

export default useStyles
