import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField
} from '@material-ui/core'
import axios from '../../../../utils/axios'
import { INTERNAL_API_URL } from '../../../../config/constants'
import { useTranslation } from 'react-i18next'
import useStyles from './QuotaForm.styles'
import { useContext, useState } from 'react'
import { SnackbarContext } from '../../../../context'

const PLAN_TYPES = {
	empty: 'empty',
	free: 'free',
	basic: 'basic',
	custom: 'custom',
	unlimited: 'unlimited'
}
const QuotaForm = ({
	planType,
	customQuota,
	setCustomQuota,
	setPlanType,
	organisation,
	setOrganisation,
	fetchQuota
}) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const { showMessage } = useContext(SnackbarContext)

	const [loading, setLoading] = useState(false)

	const setPlan = async () => {
		await axios.post(`${INTERNAL_API_URL}/organizations/${organisation.uuid}/quota/set-plan`, {
			planType
		})
	}

	const updateQuota = async () => {
		await axios.put(`${INTERNAL_API_URL}/organizations/${organisation.uuid}/quota/update`, {
			planType,
			quotas: customQuota
		})
	}

	const handlePlanChange = async (e) => {
		setPlanType(e.target.value)
	}

	const handlePlanSubmit = async (e) => {
		e.preventDefault()

		setLoading(true)

		try {
			if (planType === PLAN_TYPES.custom) {
				await updateQuota()
			} else {
				await setPlan()
			}

			setOrganisation({ ...organisation, subscription: planType })

			await fetchQuota()

			showMessage(t('success'), 'success')
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	const handleQuotaChange = (prop) => (e) => {
		setCustomQuota({ ...customQuota, [prop]: e.target.value })
	}

	return (
		<Card component={Paper}>
			<CardHeader title={t('organisation.quotaForm')} />

			<Divider />
			<form noValidate autoComplete="off" onSubmit={handlePlanSubmit}>
				<CardContent>
					<Box className={classes.form}>
						<FormControl variant="outlined" width={200} size="small">
							<InputLabel id="plan-label">Plan</InputLabel>
							<Select
								labelId="plan-label"
								id="plan"
								value={planType}
								onChange={handlePlanChange}
								label="plan"
								variant="outlined"
								size="small"
								required
							>
								{Object.values(PLAN_TYPES).map((plan, i) => (
									<MenuItem key={i} value={plan}>
										<em>{plan}</em>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					{planType === PLAN_TYPES.custom && (
						<Box className={classes.form} mt={4}>
							{Object.keys(customQuota).map((quota, i) => (
								<TextField
									key={i}
									type="number"
									label={quota}
									variant="outlined"
									value={customQuota[quota]}
									onChange={handleQuotaChange(quota)}
									required
									size="small"
									width={200}
								/>
							))}
						</Box>
					)}
				</CardContent>

				<Divider />

				<CardActions>
					<Button type={'submit'} variant="contained" color="primary" disabled={loading}>
						{t('save')}
					</Button>
				</CardActions>

				{loading && <LinearProgress />}
			</form>
		</Card>
	)
}

export default QuotaForm
