import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	form: {
		display: 'flex',
		flexWrap: 'wrap',
		gridGap: theme.spacing(2),
		'& > *': {
			width: '220px',
			minWidth: '220px'
		}
	}
}))

export default useStyles
