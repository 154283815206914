import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2)
	},
	primaryDataCard: {
		padding: theme.spacing(2)
	}
}))

export default useStyles
