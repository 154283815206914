import { useEffect, useState } from 'react'
import { Box, Typography, LinearProgress, MenuItem, Select } from '@material-ui/core'
import { addDays, format, subMonths } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import {
	BarChart,
	Bar,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

import axios from 'utils/axios'
import { BASE_API_URL } from 'config/constants'
import snakecaseKeys from 'snakecase-keys'
import { useTranslation } from 'react-i18next'
import useStyles from './Stats.styles'

const COLORS = ['#2ca02c', '#1f77b4', '#ff7f0e', '#771fb4', '#000e7f', '#a02c2c', '#77b41f']

const DecisionResultChart = ({ organizations, decisionNames }) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const [stats, setStats] = useState([])
	const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)

	const [organizationId, setOrganizationId] = useState('')
	const [name, setName] = useState('')
	const [dateFrom, setDateFrom] = useState(addDays(subMonths(new Date(), 1), 1))
	const [dateTo, setDateTo] = useState(new Date())

	const DEBOUNCE_TIMEOUT = 200
	const [loadDebounceHandler, setLoadDebounceHandler] = useState(null)
	const [loadRequestSource, setLoadRequestSource] = useState(null)

	const load = () => {
		if (!name) {
			setStats([])
			setResults([])

			return
		}
		setLoading(true)

		const params = snakecaseKeys(
			organizationId ? {
				organization_id: `eq.${organizationId}`,
				name: `eq.${name}`,
				// from: format(dateFrom, 'yyyy-MM-dd'),
				// to: format(dateTo, 'yyyy-MM-dd')
			} :  { name: `eq.${name}` },
			{ deep: true }
		)

		const source = axios.CancelToken.source()
		setLoadRequestSource(source)

		axios
			.get(`/daily_decision_results_stats`, {
				params,
				cancelToken: source.token
			})
			.then(({ data }) => {
				const names = []
				const stats = data.reduce((stats, { result, day: date, decision_count: count }) => {
					result = result.toString()
					let lastStatsItem = stats.find((item) => item.date === date)
					if (lastStatsItem && lastStatsItem.date === date) {
						lastStatsItem[result] = count
					} else {
						stats.push({
							date,
							[result]: count
						})
					}

					if (names.indexOf(result) === -1) names.push(result)

					return stats
				}, [])

				setStats(stats)
				setResults(names)

				finishLoading()
			})
			.catch((e) => {
				if (!axios.isCancel(e)) {
					finishLoading()
					setStats([])
					setResults([])
				}
			})
	}

	const cancelLoading = () => {
		if (loadRequestSource) {
			loadRequestSource.cancel(t('loading.cancel'))
		}
		if (loadDebounceHandler) {
			clearTimeout(loadDebounceHandler)
			setLoadDebounceHandler(null)
		}

		finishLoading()
	}

	const finishLoading = () => {
		setLoadRequestSource(null)
		setLoading(false)
	}

	useEffect(() => {
		cancelLoading()

		setLoadDebounceHandler(
			setTimeout(() => {
				load()
			}, DEBOUNCE_TIMEOUT)
		)
	}, [organizationId, name, dateFrom, dateTo])

	const getColors = (index) => COLORS[index % COLORS.length]

	const chartMargins = {
		top: 5,
		right: 20,
		left: 0,
		bottom: 20
	}

	return (
		<>
			<Box mb={2} display="flex" justifyContent="space-between" className={classes.headerBox}>
				<Typography variant="subtitle1" className={classes.header}>
					{t('decisions.results')}
				</Typography>

				<Box display="flex" alignItems="center" className={classes.headerBox}>
					<Select
						label={t('decisions.name')}
						id="decision-name-select"
						value={name}
						onChange={(e) => setName(e.target.value)}
						displayEmpty
					>
						<MenuItem value="">Select Decision Name</MenuItem>
						{decisionNames.map((name, index) => {
							return (
								<MenuItem key={`name-${index}`} value={name}>
									{name}
								</MenuItem>
							)
						})}
					</Select>
					{organizations && (
						<>
							<Select
								label={t('statistic.organisation')}
								id="org-select"
								value={organizationId}
								onChange={(e) => setOrganizationId(e.target.value)}
								displayEmpty
							>
								<MenuItem value="">{t('statistic.organisation.all')}</MenuItem>
								{organizations.map((org, index) => {
									return (
										<MenuItem key={`org-${index}`} value={org.uuid}>
											{org.name}
										</MenuItem>
									)
								})}
							</Select>
						</>
					)}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							variant="inline"
							format="MM/dd/yyyy"
							placeholder="From"
							value={dateFrom}
							onChange={setDateFrom}
							KeyboardButtonProps={{
								'aria-label': 'change from date'
							}}
						/>
						<Box>~</Box>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							variant="inline"
							format="MM/dd/yyyy"
							id="date-picker-inline"
							placeholder="To"
							value={dateTo}
							onChange={setDateTo}
							KeyboardButtonProps={{
								'aria-label': 'change to date'
							}}
						/>
					</MuiPickersUtilsProvider>
				</Box>
			</Box>
			{loading ? (
				<LinearProgress />
			) : name ? (
				<ResponsiveContainer width="100%" height={250}>
					<BarChart data={stats} margin={chartMargins}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" />
						<YAxis allowDecimals={false} />
						<Tooltip />
						<Legend
							iconType="circle"
							iconSize={10}
							wrapperStyle={{ position: 'relative' }}
						/>
						{results.map((name, index) => (
							<Bar
								key={`bar-${name}`}
								dataKey={name}
								stackId="a"
								fill={getColors(index)}
								maxBarSize={100}
							/>
						))}
					</BarChart>
				</ResponsiveContainer>
			) : (
				<Box textAlign="center" padding={4}>
					<Typography variant="subtitle1">{t('decisions.select_name')}</Typography>
				</Box>
			)}
		</>
	)
}

export default DecisionResultChart
