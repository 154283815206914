import { Box, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NotFound = () => {
	const { t } = useTranslation()

	return (
		<Box mt={26}>
			<Typography variant={'h4'} align={'center'}>
				{t('pageNotFound')}
			</Typography>
			<br />
			<Typography variant={'h6'} align={'center'}>
				<RouterLink to={'/'}>{t('pageNotFound.back')}</RouterLink>
			</Typography>
		</Box>
	)
}

export default NotFound
