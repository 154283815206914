import formatDate from "./formatDate";

const formatDateNew = (dateString, withTime = true) => {
    if (!dateString) return '';

    let date = new Date(dateString);
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date format');
    }

    // Adjust to UTC+2 timezone
    date.setUTCMinutes(date.getUTCMinutes() + 120);

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    };

    const formatOptions = withTime ? options : { ...options, hour: undefined, minute: undefined, second: undefined };

    const formattedDate = date.toLocaleString('en-GB', formatOptions);

    return formattedDate;
};
export default formatDateNew;