import {
	Card,
	CardHeader,
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@material-ui/core'
import { getAddressString } from '../../../../utils/AddressHelper'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EInvoices } from '../../../../components/invoicing-address/InvoicingAddressSingleInfo/components'
import { COMPANY_ADDRESS_TYPES } from '../../../../config/constants'

const CompanyContactInfo = ({ result }) => {
	const { t } = useTranslation()

	return (
		<Card component={Paper}>
			<CardHeader title={t('contact_info')} />

			<Divider />

			<Table>
				<TableBody>
					<TableRow>
						<TableCell variant={'head'} width="25%">
							{t('phone')}
						</TableCell>
						<TableCell>{result.contact_phone_number}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell variant={'head'}>{t('email')}</TableCell>
						<TableCell>{result.contact_email}</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('postalAddress')}</TableCell>
						<TableCell>
							{getAddressString(COMPANY_ADDRESS_TYPES.postal, result.company_address)}
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell variant={'head'}>{t('homePage')}</TableCell>
						<TableCell>
							{result.websites?.map(({ website }, i) => (
								<a key={i} target={'_blank'} rel="noreferrer" href={website}>
									{website}
								</a>
							))}
						</TableCell>
					</TableRow>

					{result.invoicingAddress &&
						result.invoicingAddress.einvoices &&
						result.invoicingAddress.einvoices.length > 0 && (
							<>
								<TableRow>
									<TableCell variant={'head'}>{t('eInvoiceAddress')}</TableCell>
									<TableCell>
										{result.invoicingAddress.einvoices[0]?.address}
									</TableCell>
								</TableRow>
								<EInvoices data={result.invoicingAddress.einvoices} />
							</>
						)}
				</TableBody>
			</Table>
		</Card>
	)
}

export default CompanyContactInfo
