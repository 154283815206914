import { useEffect, useState } from 'react'
import { Box, Typography, LinearProgress, MenuItem, Select } from '@material-ui/core'
import { addDays, format, subMonths } from 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts'

import axios from '../../utils/axios'
import { CHART_TYPES, CHART_TYPE_DAY, BASE_API_URL } from 'config/constants'
import snakecaseKeys from 'snakecase-keys'
import { useTranslation } from 'react-i18next'
import useStyles from './Stats.styles'

const COLORS = ['#000', '#1f77b4', '#ff7f0e', '#2ca02c', '#771fb4', '#000e7f', '#a02c2c', '#77b41f']

const InformationRequestChart = ({ organizations }) => {
	const { t } = useTranslation()

	const classes = useStyles()

	const [names, setNames] = useState([])
	const [visibilities, setVisibilities] = useState({})
	const [opacities, setOpacities] = useState({})
	const [stats, setStats] = useState([])
	const [loading, setLoading] = useState(true)

	const [organizationId, setOrganizationId] = useState('')
	const [dateFrom, setDateFrom] = useState(addDays(subMonths(new Date(), 1), 1))
	const [dateTo, setDateTo] = useState(new Date())
	const [chartType, setChartType] = useState(CHART_TYPE_DAY)

	const DEBOUNCE_TIMEOUT = 200
	const [loadDebounceHandler, setLoadDebounceHandler] = useState(null)
	const [loadRequestSource, setLoadRequestSource] = useState(null)

	const chartMargins = {
		top: 5,
		right: 20,
		left: 0,
		bottom: window.innerWidth > 640 ? 20 : 80
	}
	const load = () => {
		setLoading(true)

		const params = snakecaseKeys(
			organizationId ? {
				organization_id: `eq.${organizationId}`,
				// from: format(dateFrom, 'yyyy-MM-dd'),
				// to: format(dateTo, 'yyyy-MM-dd'),
				// type: chartType
			} : {},
			{ deep: true }
		)

		const source = axios.CancelToken.source()
		setLoadRequestSource(source)

		axios
			.get(`/daily_information_request_stats?day=gte.${format(dateFrom, 'yyyy-MM-dd')}&day=lte.${format(dateTo, 'yyyy-MM-dd')}`, {
				params,
				cancelToken: source.token
			})
			.then(({ data }) => {
				const names = []
				const totalFieldName = 'Total'

				const stats = data.reduce((stats, { name, day: date, request_count: count }) => {
					if (!name) name = 'Unknown'
					let lastStatsItem = stats.find((item) => item.date === date)
					if (lastStatsItem && lastStatsItem.date === date) {
						lastStatsItem[name] = count
						lastStatsItem[totalFieldName] += count
					} else {
						stats.push({
							date,
							[name]: count,
							[totalFieldName]: count
						})
					}
					if (!names.includes(name)) {
						names.push(name)
					}

					return stats
				}, [])

				names.unshift(totalFieldName)
				setNames(names)
				setVisibilities(
					names.reduce((visibilities, name) => {
						visibilities[name] = true
						return visibilities
					}, {})
				)
				resetOpacities()
				setStats(stats)

				finishLoading()
			})
			.catch((e) => {
				if (!axios.isCancel(e)) {
					finishLoading()
					setStats([])
				}
			})
	}

	const cancelLoading = () => {
		if (loadRequestSource) {
			loadRequestSource.cancel(t('loading.cancel'))
		}
		if (loadDebounceHandler) {
			clearTimeout(loadDebounceHandler)
			setLoadDebounceHandler(null)
		}

		finishLoading()
	}

	const finishLoading = () => {
		setLoadRequestSource(null)
		setLoading(false)
	}

	useEffect(() => {
		cancelLoading()

		setLoadDebounceHandler(
			setTimeout(() => {
				load()
			}, DEBOUNCE_TIMEOUT)
		)
	}, [organizationId, dateFrom, dateTo, chartType])

	const getColors = (index) => COLORS[index % COLORS.length]

	const resetOpacities = (name = null) => {
		const opacities = names.reduce((opacities, n) => {
			opacities[n] = !name || name === n ? 1 : 0.25
			return opacities
		}, {})

		setOpacities(opacities)
	}

	const handleLegendClick = ({ value: name }) => {
		const visibility = !visibilities[name]
		setVisibilities({
			...visibilities,
			[name]: visibility
		})
		resetOpacities(visibility ? name : null)
	}

	const handleMouseEnter = ({ value: name }) => {
		setOpacities({
			...names.reduce((opacities, name) => {
				opacities[name] = 0.25
				return opacities
			}, {}),
			[name]: 1
		})
	}

	const handleMouseLeave = () => {
		resetOpacities()
	}

	return (
		<>
			<Box mb={2} display="flex" justifyContent="space-between" className={classes.headerBox}>
				<Typography variant="subtitle1" className={classes.header}>
					{t('information_requests')}
				</Typography>

				<Box display="flex" alignItems="center" className={classes.headerBox}>
					{organizations && (
						<>
							<Select
								label={t('statistic.organisation')}
								id="org-select"
								value={organizationId}
								onChange={(e) => setOrganizationId(e.target.value)}
								displayEmpty
							>
								<MenuItem value="">All Organizations</MenuItem>
								{organizations.map((org, index) => {
									return (
										<MenuItem key={`org-${index}`} value={org.uuid}>
											{org.name}
										</MenuItem>
									)
								})}
							</Select>
						</>
					)}
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							variant="inline"
							format="MM/dd/yyyy"
							placeholder="From"
							value={dateFrom}
							onChange={setDateFrom}
							KeyboardButtonProps={{
								'aria-label': 'change from date'
							}}
						/>
						<Box mx={1}>~</Box>
						<KeyboardDatePicker
							disableToolbar
							disableFuture
							variant="inline"
							format="MM/dd/yyyy"
							id="date-picker-inline"
							placeholder="To"
							value={dateTo}
							onChange={setDateTo}
							KeyboardButtonProps={{
								'aria-label': 'change to date'
							}}
						/>
					</MuiPickersUtilsProvider>
					<Box>
						<Select
							label="Chart Type"
							value={chartType}
							onChange={(e) => setChartType(e.target.value)}
						>
							{CHART_TYPES.map(([type, title], index) => {
								return (
									<MenuItem key={`chart-type-${index}`} value={type}>
										{t(`chart_type.${type}`)}
									</MenuItem>
								)
							})}
						</Select>
					</Box>
				</Box>
			</Box>
			{loading ? (
				<LinearProgress />
			) : (
				<ResponsiveContainer width="100%" height={250}>
					<LineChart data={stats} margin={chartMargins}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" />
						<YAxis allowDecimals={false} />
						<Tooltip />
						<Legend
							iconType="circle"
							iconSize={10}
							onClick={handleLegendClick}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
							wrapperStyle={{ position: 'relative' }}
						/>
						{names.map((name, index) => (
							<Line
								key={`line-${name}`}
								type="monotone"
								dataKey={name}
								stroke={getColors(index)}
								strokeOpacity={opacities[name]}
								hide={!visibilities[name]}
								// connectNulls
							/>
						))}
					</LineChart>
				</ResponsiveContainer>
			)}
		</>
	)
}

export default InformationRequestChart
