import {
	Box,
	Button,
	Grid,
	IconButton,
	InputBase,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	TableSortLabel
} from '@material-ui/core'
import { BASE_API_URL, ORDER_DIRS, PAGE_SIZE } from '../../../../config/constants'
import { useEffect, useState } from 'react'
import snakecaseKeys from 'snakecase-keys'
import axios from '../../../../utils/axios'
import ApprovalsTableRow from './ApprovalsRow/ApprovalsTableRow'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from '../Approvals.styles'

const ApprovalsTable = ({ setError }) => {
	const classes = useStyles()

	const { t } = useTranslation()

	const [results, setResults] = useState([])

	const [loading, setLoading] = useState(false)

	const [orderBy, setOrderBy] = useState('status')
	const [orderDir, setOrderDir] = useState(ORDER_DIRS.ASC)

	const [keyword, setKeyword] = useState('')
	const [offset, setOffset] = useState(0)
	const [loadFinished, setLoadFinish] = useState(false)

	useEffect(() => {
		loadData()
	}, [offset, orderBy, orderDir, keyword])

	const loadData = () => {
		setLoading(true)
		setLoadFinish(false)

		const params = snakecaseKeys(
			{
				limit: PAGE_SIZE,
				offset
			},
			{ deep: true }
		)

		axios
			.get(`${BASE_API_URL}/v3/prompts`, {
				params
			})
			.then(({ data }) => {
				let temp = results
				temp = temp.concat(data)
				setResults(temp)

				if (data.length < PAGE_SIZE) setLoadFinish(true)
			})
			.catch((e) => {
				setError(true)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const resetSearch = () => {
		setOffset(0)
		setResults([])
	}

	const handleSearchFormSubmit = (event) => {
		event.preventDefault()
		const value = event.target[0].value
		if (value !== keyword) {
			setKeyword(value)
		} else if (!offset) {
			loadData()
		}
		resetSearch()
	}

	const headCells = [
		{ id: 'status', label: t('status') },
		{ id: 'email', label: t('email') },
		{ id: 'country', label: t('country') },
		{ id: 'organization_id', label: t('organisation') },
		{ id: 'person_id', label: t('person') },
		{ id: 'createdAt', label: t('createdAt') },
		{ id: 'finalized_at', label: t('finalizedAt') }
	]

	const handleSortChange = (newOrderBy) => {
		if (newOrderBy === orderBy)
			setOrderDir(orderDir === ORDER_DIRS.DESC ? ORDER_DIRS.ASC : ORDER_DIRS.DESC)
		else setOrderDir(ORDER_DIRS.ASC)

		setOrderBy(newOrderBy)

		resetSearch()
	}

	return (
		<>
			<Box mt={4}>
				<Paper
					elevation={1}
					component="form"
					className={classes.searchRoot}
					onSubmit={handleSearchFormSubmit}
				>
					<InputBase
						placeholder={t('organisation.search.placeholder')}
						inputProps={{ 'aria-label': 'search google maps' }}
					/>
					<IconButton type="submit" className="iconButton" aria-label="search">
						<SearchIcon />
					</IconButton>
				</Paper>
			</Box>

			<TableContainer component={Paper}>
				<Table aria-label="Results Table">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={orderBy === headCell.id ? orderDir : false}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={
											orderBy === headCell.id ? orderDir : ORDER_DIRS.ASC
										}
										onClick={() => {
											handleSortChange(headCell.id, headCell.company)
										}}
									>
										{t(headCell.label)}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{results.map((result, i) => (
							<ApprovalsTableRow key={i} data={result} />
						))}
					</TableBody>
					<TableFooter>
						{loading && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1}>
									<LinearProgress />
								</TableCell>
							</TableRow>
						)}

						{!loadFinished && (
							<TableRow>
								<TableCell colSpan={headCells.length + 1}>
									<Grid
										container
										justifyContent="center"
										direction="column"
										alignItems="center"
									>
										{loading && <LinearProgress />}
										<Button
											disabled={loading}
											variant="outlined"
											color="primary"
											onClick={() => setOffset(offset + PAGE_SIZE)}
										>
											{t('load_more')}
										</Button>
									</Grid>
								</TableCell>
							</TableRow>
						)}
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	)
}

export default ApprovalsTable
