import { useEffect, useState } from 'react'
import axios from '../../../utils/axios'
import { DB_API_URL } from '../../../config/constants'
import { useParams, Link } from 'react-router-dom'
import {
	Box,
	Card,
	CardHeader,
	Divider,
	Grid,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@material-ui/core'
import Error from '../../../layouts/Main/components/Error'
import { useTranslation } from 'react-i18next'
import formatDate from '../../../utils/formatDate'
import React from 'react'
import { HistoryTable } from '../../../components/history'

const PersonDetail = () => {
	const { t } = useTranslation()

	const params = useParams()

	const [result, setResult] = useState(null)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)

	const [prompts, setPrompts] = useState([])

	const [decisions, setDecisions] = useState([])

	const [histories, setHistories] = useState([])

	useEffect(() => {
		const load = async () => {
			try {
				setLoading(true)

				const { data } = await axios.get(`${DB_API_URL}/persons/${params.personId}`)

				setResult(data)
				setLoading(false)
			} catch (error) {
				setError(true)
			}
		}

		const loadPrompts = async () => {
			try {
				const { data } = await axios.get(
					`${DB_API_URL}/persons/${params.personId}/approvals`
				)
				setPrompts(data)
			} catch (error) {
			} finally {
			}
		}

		const loadDecisions = async () => {
			try {
				const { data } = await axios.get(`${DB_API_URL}/persons/${params.personId}`)
				console.log('data', data)
				setDecisions(data)
			} catch (error) {
			} finally {
			}
		}

		const loadHistory = async () => {
			try {
				const { data } = await axios.get(`${DB_API_URL}/persons/${params.personId}/history`)

				setHistories(data)
			} catch (error) {
			} finally {
			}
		}

		load()
		loadPrompts()
		loadDecisions()
		loadHistory()
	}, [params.personId])

	return (
		<div>
			<nav>
				<Link to={`/companies/${params.id}`}>Back to company</Link>
			</nav>
			{error ? (
				<Error />
			) : loading ? (
				<LinearProgress />
			) : (
				<Box mt={6} px={2}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<Card component={Paper}>
								<CardHeader title={t('person')} />

								<Divider />

								<Table>
									<TableBody>
										<TableRow>
											<TableCell variant={'head'}>{t('name')}</TableCell>
											<TableCell>
												{result.firstName} {result.lastName}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('email')}</TableCell>
											<TableCell>{result.email}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('phone')}</TableCell>
											<TableCell>{result.phoneNumber}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('status')}</TableCell>
											<TableCell>{result.status}</TableCell>
										</TableRow>

										<TableRow>
											<TableCell variant={'head'}>{t('role')}</TableCell>
											<TableCell>
												{result && result.roles && result.roles.join(', ')}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Card component={Paper}>
								<CardHeader title={t('details')} />

								<Divider />

								<Table>
									<TableBody>
										<TableRow>
											<TableCell variant={'head'}>{t('address')}</TableCell>
											<TableCell>{result.address}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>
												{t('date_of_birth')}
											</TableCell>
											<TableCell>{result.dateOfBirth}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('gender')}</TableCell>
											<TableCell>{result.gender}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>
												{t('language_code')}
											</TableCell>
											<TableCell>{result.languageCode}</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('modified')}</TableCell>
											<TableCell>
												{result.modifiedAt
													? formatDate(result.modifiedAt)
													: ''}
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell variant={'head'}>{t('created')}</TableCell>
											<TableCell>{formatDate(result.createdAt)}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card component={Paper}>
								<CardHeader title={t('prompt_list')} />

								<Divider />
								<PromptListTable prompts={prompts} t={t} />
							</Card>
						</Grid>

						<Grid item xs={12}>
							<Card component={Paper}>
								<CardHeader title={t('decisions')} />

								<Divider />
								<DecisionListTable decisions={decisions} t={t} />
							</Card>
						</Grid>

						<Grid item xs={12}>
							<Card component={Paper}>
								<CardHeader title={t('history')} />

								<Divider />
								<HistoryTable histories={histories} />
							</Card>
						</Grid>
					</Grid>
				</Box>
			)}
		</div>
	)
}

const PromptListTable = ({ prompts, t }) => (
	<Table>
		<TableHead>
			<TableRow>
				<TableCell>{t('email')}</TableCell>
				<TableCell>{t('created')}</TableCell>
				<TableCell>{t('finalized_at')}</TableCell>
				<TableCell>{t('redirect_url')}</TableCell>
				<TableCell>{t('status')}</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{prompts.map((prompt, i) => (
				<TableRow key={`prompt-${i}`}>
					<TableCell>{prompt.email}</TableCell>
					<TableCell>{new Date(prompt.createdAt).toLocaleString()}</TableCell>
					<TableCell>{new Date(prompt.finalizedAt).toLocaleString()}</TableCell>
					<TableCell>{prompt.redirectUrl}</TableCell>
					<TableCell>{prompt.status}</TableCell>
				</TableRow>
			))}
		</TableBody>
	</Table>
)

const DecisionListTable = ({ decisions, t }) => (
	<Table>
		<TableHead>
			<TableRow>
				<TableCell>{t('name')}</TableCell>
				<TableCell>{t('result')}</TableCell>
				<TableCell>{t('company_name')}</TableCell>
				<TableCell>{t('person_name')}</TableCell>
				<TableCell>{t('email')}</TableCell>
				<TableCell>{t('created_by')}</TableCell>
				<TableCell>{t('created_at')}</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{/*{decisions.map((decision, i) => (*/}
			{decisions?.data && (
				<TableRow key={`prompt`}>
					<TableCell>{decisions.data.decisionName}</TableCell>
					<TableCell>{decisions.data.result}</TableCell>
					<TableCell>{decisions.data.companyName}</TableCell>
					<TableCell>
						{decisions.data.firstName} {decisions.data.lastName}
					</TableCell>
					<TableCell>{decisions.data.email}</TableCell>
					<TableCell>{decisions.data.createdBy}</TableCell>
					<TableCell>{formatDate(decisions.createdAt)}</TableCell>
				</TableRow>
			)}
			{/*))}*/}
		</TableBody>
	</Table>
)

export default PersonDetail
