import { useTranslation } from 'react-i18next'
import React, { useContext, useState } from 'react'
import { SnackbarContext } from '../../../../context'
import axios from '../../../../utils/axios'
import { INTERNAL_API_URL } from '../../../../config/constants'
import { Box, Button, Dialog, DialogTitle, Grid, TextField } from '@material-ui/core'

const CompanyDomainForm = ({
	businessId,
	defaultName,
	loadDomains,
	handleModalClose,
	loading,
	setLoading
}) => {
	const { t } = useTranslation()
	const { showMessage } = useContext(SnackbarContext)

	const [domain, setDomain] = useState({
		primaryBusinessId: businessId,
		name: defaultName,
		domain: ''
	})

	const handleChange = (prop) => (event) => {
		setDomain({ ...domain, [prop]: event.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		setLoading(true)

		try {
			await axios.post(`${INTERNAL_API_URL}/global/domains`, domain)

			await loadDomains()

			handleModalClose()
		} catch (e) {
			showMessage(t('error'), 'error')
		}

		setLoading(false)
	}

	return (
		<Dialog
			open={true}
			onClose={handleModalClose}
			aria-labelledby="domain-form-modal-title"
			aria-describedby="domain-form-modal-description"
		>
			<DialogTitle id="simple-modal-title">{t('company.emailDomains.create')}</DialogTitle>

			<Box p={4}>
				<form noValidate autoComplete="off" onSubmit={handleSubmit}>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={12}>
							<TextField
								type="text"
								required
								label={t('company.emailDomains.name')}
								fullWidth
								variant="outlined"
								onChange={handleChange('name')}
								value={domain.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="text"
								required
								label={t('company.emailDomains.domain')}
								fullWidth
								variant="outlined"
								onChange={handleChange('domain')}
								value={domain.domain}
							/>
						</Grid>
					</Grid>

					<Box mt={8} display={'flex'} gridGap={16} justifyContent={'flex-end'}>
						<Button color="secondary" onClick={handleModalClose} disabled={loading}>
							{t('cancel')}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={loading}
						>
							{t('save')}
						</Button>
					</Box>
				</form>
			</Box>
		</Dialog>
	)
}

export default CompanyDomainForm
